import { Facebook, Twitter, Instagram, Mail } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="bg-gray-900 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="grid md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-xl font-bold mb-4">Prince Wako Foundation</h3>
            <p className="text-gray-400">
              Building a brighter future through sustainable community development.
            </p>
          </div>

          <div>
            <h4 className="font-semibold mb-4">Quick Links</h4>
            <ul className="space-y-2">
              <li><Link to="/about" className="text-gray-400 hover:text-white transition-colors">About Us</Link></li>
              <li><Link to="/initiatives" className="text-gray-400 hover:text-white transition-colors">Our Programs</Link></li>
              <li><Link to="/impact" className="text-gray-400 hover:text-white transition-colors">Impact</Link></li>
              <li><Link to="/contact" className="text-gray-400 hover:text-white transition-colors">Contact</Link></li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold mb-4">Resources</h4>
            <ul className="space-y-2">
              <li><Link to="/resources/students" className="text-gray-400 hover:text-white transition-colors">Student Resources</Link></li>
              <li><Link to="/resources/research" className="text-gray-400 hover:text-white transition-colors">Research & Publications</Link></li>
              <li><Link to="/resources/parents" className="text-gray-400 hover:text-white transition-colors">Parent Resources</Link></li>
              <li><Link to="/resources/partners" className="text-gray-400 hover:text-white transition-colors">Partner Resources</Link></li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold mb-4">Contact Us</h4>
            <ul className="space-y-2 text-gray-400">
              <li>P.O. Box 2008</li>
              <li>Jinja, Uganda</li>
              <li>East AFrica</li>
              <li>Phone: +256 706 474 831</li>
              <li>Email: princewakofoundation@gmail.com</li>
            </ul>
            <div className="flex space-x-4 mt-4">
            <a
              href="https://www.facebook.com/PrinceWakoFoundation"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-white transition-colors"
            >
              <Facebook size={24} />
            </a>

            <a
              href="https://x.com/princewakoorg"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-white transition-colors"
            >
              <Twitter size={24} />
            </a>

            <a
              href="https://www.instagram.com/princewakofoundation"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-400 hover:text-white transition-colors"
            >
              <Instagram size={24} />
            </a>

            <a
              href="mailto:princewakofoundation@gmail.com"
              className="text-gray-400 hover:text-white transition-colors"
            >
              <Mail size={24} />
            </a>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-800 mt-8 pt-8 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} Prince Wako Foundation. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}