import { BookOpen, Heart, Home, Droplet } from 'lucide-react';
import { Link } from 'react-router-dom';
const initiatives = [
  {
    icon: BookOpen,
    title: 'Educational Programs',
    description: 'Providing Quality education and Learning Resources to Empower Future Generations.',
    color: 'from-blue-500 to-indigo-600'
  },
  {
    icon: Heart,
    title: 'Healthcare Access',
    description: 'Boosting Access to Essential Healthcare Services and Support  ',
    color: 'from-pink-500 to-rose-600'
  },
  {
    icon: Home,
    title: 'Community Development',
    description: 'Practical Skill Development and Women Group Microfinancing Solutions',
    color: 'from-amber-500 to-orange-600'
  },
  {
    icon: Droplet,
    title: 'Environmental Sustainability',
    description: 'Clean Water Access and Tree Planting Initiatives',
    color: 'from-cyan-500 to-blue-600'
  }
];

export default function Initiatives() {
  return (
    <section className="py-20 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16 animate-fade-in">
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
            Our Key Initiatives
          </h2>
          <p className="text-lg text-gray-600">
            Through our focused initiatives, we create lasting positive change in communities.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {initiatives.map((initiative, index) => (
            <div 
              key={index} 
              className="group relative bg-white rounded-xl shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 overflow-hidden"
            >
              <div className="absolute inset-0 bg-gradient-to-r opacity-0 group-hover:opacity-10 transition-opacity duration-300" />
              <div className="p-6 relative">
                <div className={`w-12 h-12 bg-gradient-to-r ${initiative.color} rounded-lg flex items-center justify-center mb-4 transform group-hover:scale-110 transition-transform duration-300`}>
                  <initiative.icon className="text-white" size={24} />
                </div>
                <h3 className="text-xl font-semibold mb-3 group-hover:text-indigo-600 transition-colors duration-300">
                  {initiative.title}
                </h3>
                <p className="text-gray-600 mb-4 group-hover:text-gray-700 transition-colors duration-300">
                  {initiative.description}
                </p>
                <Link
                  to="/initiatives"
                  className="text-gray-900 font-semibold group hover:text-indigo-600 transition-colors duration-300 flex items-center gap-2"
                >
                  Learn More 
                  <span className="transform group-hover:translate-x-1 transition-transform duration-300">→</span>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}