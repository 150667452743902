import React from 'react';
import { Plus, Minus } from 'lucide-react';

const faqs = [
  {
    question: "How can I volunteer with the foundation?",
    answer: "You can volunteer by filling out our volunteer application form. We offer various opportunities including teaching, healthcare assistance, and community development projects."
  },
  {
    question: "Where do my donations go?",
    answer: "Your donations directly support our community initiatives. 80% goes to program implementation, 15% to administrative costs, and 5% to outreach and awareness."
  },
  {
    question: "How can I partner with the foundation?",
    answer: "We welcome partnerships with organizations that share our vision. Please contact us through our partnership inquiry form or email us at partnerships@princewako.org."
  },
  {
    question: "What areas do you operate in?",
    answer: "We primarily operate in Uganda, with projects across multiple regions including Kampala, Jinja, Gulu, and Mbarara."
  }
];

export default function ContactFAQ() {
  const [openIndex, setOpenIndex] = React.useState<number | null>(null);

  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold mb-4">Frequently Asked Questions</h2>
            <p className="text-lg text-gray-600">
              Find quick answers to common questions about our foundation.
            </p>
          </div>

          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="bg-white rounded-xl shadow-sm overflow-hidden"
              >
                <button
                  className="w-full px-6 py-4 text-left flex items-center justify-between"
                  onClick={() => setOpenIndex(openIndex === index ? null : index)}
                >
                  <span className="font-semibold">{faq.question}</span>
                  {openIndex === index ? (
                    <Minus className="text-gray-900" size={20} />
                  ) : (
                    <Plus className="text-gray-900" size={20} />
                  )}
                </button>
                
                {openIndex === index && (
                  <div className="px-6 pb-4">
                    <p className="text-gray-600">{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="text-center mt-12">
            <p className="text-gray-600 mb-4">
              Still have questions? We're here to help!
            </p>
            <button
              onClick={() => document.getElementById('contact-form')?.scrollIntoView({ behavior: 'smooth' })}
              className="bg-gray-900 text-white px-8 py-3 rounded-full font-semibold hover:bg-gray-800 transition-colors"
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}