import { Users, GraduationCap, Heart, Home } from 'lucide-react';

const stats = [
  {
    icon: Users,
    value: '10,000+',
    label: 'Lives Impacted',
    description: 'Through our various programs and initiatives',
    gradient: 'from-blue-500 to-indigo-500'
  },
  {
    icon: GraduationCap,
    value: '500+',
    label: 'Students Supported',
    description: 'With education and mentorship programs',
    gradient: 'from-pink-500 to-rose-500'
  },
  {
    icon: Heart,
    value: '10+',
    label: 'Healthcare Projects',
    description: 'Providing essential medical services',
    gradient: 'from-purple-500 to-indigo-500'
  },
  {
    icon: Home,
    value: '25+',
    label: 'Communities Served',
    description: 'Across different regions in Uganda',
    gradient: 'from-emerald-500 to-teal-500'
  }
];

export default function ImpactOverview() {
  return (
    <section className="py-20 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
            Our Impact
          </h2>
          <p className="text-lg text-gray-600">
            Through dedication and community partnership, we've achieved significant milestones in our mission to transform lives.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {stats.map((stat, index) => (
            <div 
              key={index} 
              className="group bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
            >
              <div className={`w-16 h-16 bg-gradient-to-r ${stat.gradient} rounded-full flex items-center justify-center mx-auto mb-4 transform group-hover:scale-110 transition-transform duration-300`}>
                <stat.icon className="text-white" size={32} />
              </div>
              <div className="text-3xl font-bold mb-2 text-center bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
                {stat.value}
              </div>
              <div className="text-xl font-semibold mb-2 text-center group-hover:text-indigo-600 transition-colors duration-300">
                {stat.label}
              </div>
              <p className="text-gray-600 text-center group-hover:text-gray-700 transition-colors duration-300">
                {stat.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}