import Header from '../components/Header';
import InitiativesHero from '../components/initiatives/InitiativesHero';
import InitiativesOverview from '../components/initiatives/InitiativesOverview';
import FeaturedInitiatives from '../components/initiatives/FeaturedInitiatives';
import InitiativesMap from '../components/initiatives/InitiativesMap';
import InitiativesCTA from '../components/initiatives/InitiativesCTA';
import NewsletterSubscription from '../components/NewsletterSubscription';
import Footer from '../components/Footer';

export default function Initiatives() {
  return (
    <div className="min-h-screen relative">
      <Header />
      <main className="relative z-0">
        <InitiativesHero />
        <InitiativesOverview />
        <FeaturedInitiatives />
        <InitiativesMap />
        <InitiativesCTA />
        <NewsletterSubscription />
      </main>
      <Footer />
    </div>
  );
}