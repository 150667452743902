import React from 'react';
import { BookOpen, Download, Users, FileText, Heart, Lightbulb } from 'lucide-react';
import Header from '../components/Header';
import NewsletterSubscription from '../components/NewsletterSubscription';
import ArticlesSection from '../components/resources/ArticlesSection';
import Footer from '../components/Footer';

const resources = [
  {
    category: 'Parenting Guides & Workshops',
    items: [
      {
        title: 'Early Childhood Development Guide',
        description: 'Essential milestones and development strategies for young children',
        link: '#',
        type: 'PDF'
      },
      {
        title: 'Mental Health & Well-being',
        description: 'Supporting your child\'s emotional and mental health',
        link: '#',
        type: 'Guide'
      },
      {
        title: 'Nutrition & Health Tips',
        description: 'Healthy eating habits and lifestyle guidance for children',
        link: '#',
        type: 'Article'
      },
      {
        title: 'Upcoming Workshops',
        description: 'Schedule of parenting workshops and seminars',
        link: '#',
        type: 'Calendar'
      }
    ]
  },
  {
    category: 'Educational Support',
    items: [
      {
        title: 'Homework Helper Guide',
        description: 'Tips and strategies to support your child\'s learning at home',
        link: '#',
        type: 'PDF'
      },
      {
        title: 'Study Routine Templates',
        description: 'Customizable schedules and planning tools',
        link: '#',
        type: 'Resource'
      },
      {
        title: 'Local Tutoring Programs',
        description: 'Information about our after-school support services',
        link: '#',
        type: 'Guide'
      },
      {
        title: 'Digital Learning Resources',
        description: 'Curated online educational tools and platforms',
        link: '#',
        type: 'Article'
      }
    ]
  },
  {
    category: 'Financial Planning & Scholarships',
    items: [
      {
        title: 'Scholarship Application Guide',
        description: 'How to help your child apply for educational funding',
        link: '#',
        type: 'PDF'
      },
      {
        title: 'Education Savings Guide',
        description: 'Long-term planning for your child\'s education',
        link: '#',
        type: 'Guide'
      },
      {
        title: 'Financial Aid Resources',
        description: 'Available support programs and how to apply',
        link: '#',
        type: 'Article'
      },
      {
        title: 'Budget Planning Tools',
        description: 'Templates for managing educational expenses',
        link: '#',
        type: 'Resource'
      }
    ]
  }
];

const parentArticles = [
  {
    title: 'Supporting Your Child\'s Educational Journey',
    excerpt: 'Expert advice on how to create an enriching learning environment at home and support your child\'s academic growth.',
    image: 'https://images.unsplash.com/photo-1543269865-cbf427effbad?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80',
    author: 'Dr. Maria Rodriguez',
    readTime: '12 min read',
    category: 'Education',
    cta: {
      text: 'Get Parent Guide',
      action: '/parent-guide'
    }
  },
  {
    title: 'Financial Planning for Your Child\'s Education',
    excerpt: 'A comprehensive guide to planning and saving for your child\'s educational future, including scholarship opportunities.',
    image: 'https://images.unsplash.com/photo-1554224155-8d04cb21cd6c?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80',
    author: 'James Wilson',
    readTime: '15 min read',
    category: 'Financial Planning',
    cta: {
      text: 'Start Planning Now',
      action: '/financial-planning'
    }
  },
  {
    title: 'Building Strong Parent-Teacher Relationships',
    excerpt: 'Learn how to effectively communicate and collaborate with teachers to support your child\'s academic success.',
    image: 'https://images.unsplash.com/photo-1577896851231-70ef18881754?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80',
    author: 'Lisa Thompson',
    readTime: '8 min read',
    category: 'Communication',
    cta: {
      text: 'Read More & Register',
      action: '/parent-workshop'
    }
  }
];

export default function ParentResources() {
  return (
    <div className="min-h-screen">
      <Header />
      
      <main className="pt-20">
        {/* Hero Section */}
        <section className="bg-gray-900 text-white py-20">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="text-4xl md:text-5xl font-bold mb-6">Resources for Parents</h1>
              <p className="text-xl text-gray-300">
                Supporting parents with tools and guidance to help their children thrive academically and personally.
              </p>
            </div>
          </div>
        </section>

        {/* Resources Grid */}
        <section className="py-20">
          <div className="container mx-auto px-4">
            <div className="max-w-5xl mx-auto">
              {resources.map((section, index) => (
                <div key={index} className="mb-16">
                  <h2 className="text-3xl font-bold mb-8">{section.category}</h2>
                  <div className="grid md:grid-cols-2 gap-8">
                    {section.items.map((item, idx) => (
                      <div key={idx} className="bg-white rounded-xl shadow-lg p-6">
                        <div className="flex items-start gap-4">
                          <div className="w-12 h-12 bg-gray-900 rounded-lg flex items-center justify-center shrink-0">
                            {item.type === 'PDF' ? (
                              <Download className="text-white" size={24} />
                            ) : item.type === 'Guide' ? (
                              <BookOpen className="text-white" size={24} />
                            ) : item.type === 'Article' ? (
                              <FileText className="text-white" size={24} />
                            ) : item.type === 'Calendar' ? (
                              <Heart className="text-white" size={24} />
                            ) : item.type === 'Resource' ? (
                              <Lightbulb className="text-white" size={24} />
                            ) : (
                              <Users className="text-white" size={24} />
                            )}
                          </div>
                          <div>
                            <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                            <p className="text-gray-600 mb-4">{item.description}</p>
                            <a
                              href={item.link}
                              className="text-gray-900 font-semibold hover:text-gray-700 transition-colors inline-flex items-center gap-2"
                            >
                              Access Resource
                              <Download size={20} />
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Community Support Section */}
        <section className="bg-gray-50 py-20">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-bold mb-6">Join Our Parent Community</h2>
              <p className="text-lg text-gray-600 mb-8">
                Connect with other parents, share experiences, and access exclusive resources through our community programs.
              </p>
              <div className="grid md:grid-cols-2 gap-8">
                <div className="bg-white rounded-xl p-6 text-left">
                  <h3 className="text-xl font-semibold mb-3">Parent Support Groups</h3>
                  <p className="text-gray-600 mb-4">
                    Join our monthly meetings to discuss challenges, share solutions, and build a supportive network.
                  </p>
                  <button className="bg-gray-900 text-white px-6 py-2 rounded-full font-semibold hover:bg-gray-800 transition-colors">
                    Learn More
                  </button>
                </div>
                <div className="bg-white rounded-xl p-6 text-left">
                  <h3 className="text-xl font-semibold mb-3">Volunteer Opportunities</h3>
                  <p className="text-gray-600 mb-4">
                    Get involved in our programs and help create positive change in children's lives.
                  </p>
                  <button className="bg-gray-900 text-white px-6 py-2 rounded-full font-semibold hover:bg-gray-800 transition-colors">
                    Get Involved
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Add Articles Section before Newsletter */}
        <ArticlesSection
          title="Featured Articles & Resources"
          description="Expert insights and practical guides to help you support your child's educational journey."
          articles={parentArticles}
        />

        <NewsletterSubscription />
      </main>

      <Footer />
    </div>
  );
}