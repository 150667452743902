import React from 'react';
import Header from '../components/Header';
import DonateHero from '../components/donate/DonateHero';
import DonateImpact from '../components/donate/DonateImpact';
import DonationOptions from '../components/donate/DonationOptions';
import AlternativeDonations from '../components/donate/AlternativeDonations';
import DonateCTA from '../components/donate/DonateCTA';
import NewsletterSubscription from '../components/NewsletterSubscription';
import Footer from '../components/Footer';

export default function Donate() {
  return (
    <div className="min-h-screen">
      <Header />
      <div className="pt-20">
        <DonateHero />
        <DonateImpact />
        <DonationOptions />
        <AlternativeDonations />
        <DonateCTA />
        <NewsletterSubscription />
      </div>
      <Footer />
    </div>
  );
}