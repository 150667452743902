import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Initiatives from './components/Initiatives';
import Impact from './components/Impact';
import CallToAction from './components/CallToAction';
import NewsletterSubscription from './components/NewsletterSubscription';
import Footer from './components/Footer';

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow">
        <Hero />
        <About />
        <Initiatives />
        <Impact />
        <CallToAction />
        <NewsletterSubscription />
      </main>
      <Footer />
    </div>
  );
}

export default App;