import React, { useState } from 'react';
import { CreditCard, Repeat, Building2, Gift, Heart } from 'lucide-react';

const donationAmounts = [10, 25, 50, 100];
const frequencies = ['One-time', 'Monthly', 'Quarterly', 'Yearly'];

export default function DonationOptions() {
  const [selectedAmount, setSelectedAmount] = useState<number | 'custom'>(25);
  const [customAmount, setCustomAmount] = useState('');
  const [frequency, setFrequency] = useState('One-time');

  const handleCustomAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '' || /^\d+$/.test(value)) {
      setCustomAmount(value);
      setSelectedAmount('custom');
    }
  };

  return (
    <section className="py-20 bg-gradient-to-b from-gray-50 to-white" id="donation-options">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-rose-600 to-purple-600 bg-clip-text text-transparent">
              Make a Donation
            </h2>
            <p className="text-lg text-gray-600">
              Choose how you'd like to support our mission.
            </p>
          </div>

          <div className="bg-white rounded-xl shadow-xl p-8 relative overflow-hidden">
            <div className="absolute inset-0 bg-gradient-to-br from-rose-50 to-purple-50 opacity-50" />
            
            <div className="relative space-y-8">
              {/* Donation Amount */}
              <div>
                <h3 className="text-xl font-semibold mb-4">Select Amount</h3>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
                  {donationAmounts.map((amount) => (
                    <button
                      key={amount}
                      className={`group relative py-3 rounded-lg font-semibold transition-all duration-300 ${
                        selectedAmount === amount
                          ? 'bg-gradient-to-r from-rose-500 to-purple-500 text-white transform scale-105'
                          : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                      }`}
                      onClick={() => setSelectedAmount(amount)}
                    >
                      <span className="relative z-10 flex items-center justify-center gap-2">
                        ${amount}
                        {selectedAmount === amount && (
                          <Heart className="animate-pulse" size={16} />
                        )}
                      </span>
                    </button>
                  ))}
                </div>
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Custom Amount"
                    value={customAmount}
                    onChange={handleCustomAmountChange}
                    className={`w-full px-6 py-3 border rounded-lg transition-all duration-300 ${
                      selectedAmount === 'custom'
                        ? 'border-rose-500 ring-2 ring-rose-500/20'
                        : 'border-gray-300 hover:border-gray-400 focus:border-rose-500 focus:ring-2 focus:ring-rose-500/20'
                    }`}
                  />
                  <span className="absolute left-4 top-1/2 -translate-y-1/2 text-gray-500">$</span>
                </div>
              </div>

              {/* Donation Frequency */}
              <div>
                <h3 className="text-xl font-semibold mb-4">Select Frequency</h3>
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  {frequencies.map((freq) => (
                    <button
                      key={freq}
                      className={`relative py-3 rounded-lg font-semibold transition-all duration-300 ${
                        frequency === freq
                          ? 'bg-gradient-to-r from-rose-500 to-purple-500 text-white transform scale-105'
                          : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
                      }`}
                      onClick={() => setFrequency(freq)}
                    >
                      {freq}
                    </button>
                  ))}
                </div>
              </div>

              {/* Payment Methods */}
              <div>
                <h3 className="text-xl font-semibold mb-4">Payment Method</h3>
                <div className="grid md:grid-cols-2 gap-4">
                  <button className="group flex items-center gap-3 p-4 border rounded-lg hover:bg-gray-50 transition-all duration-300 hover:border-rose-500">
                    <div className="w-10 h-10 bg-gradient-to-r from-rose-500 to-purple-500 rounded-lg flex items-center justify-center transform group-hover:scale-110 transition-transform duration-300">
                      <CreditCard className="text-white" size={20} />
                    </div>
                    <span>Credit/Debit Card</span>
                  </button>
                  <button className="group flex items-center gap-3 p-4 border rounded-lg hover:bg-gray-50 transition-all duration-300 hover:border-rose-500">
                    <div className="w-10 h-10 bg-gradient-to-r from-blue-500 to-indigo-500 rounded-lg flex items-center justify-center transform group-hover:scale-110 transition-transform duration-300">
                      <Repeat className="text-white" size={20} />
                    </div>
                    <span>Bank Transfer</span>
                  </button>
                  <button className="group flex items-center gap-3 p-4 border rounded-lg hover:bg-gray-50 transition-all duration-300 hover:border-rose-500">
                    <div className="w-10 h-10 bg-gradient-to-r from-amber-500 to-orange-500 rounded-lg flex items-center justify-center transform group-hover:scale-110 transition-transform duration-300">
                      <Building2 className="text-white" size={20} />
                    </div>
                    <span>Corporate Giving</span>
                  </button>
                  <button className="group flex items-center gap-3 p-4 border rounded-lg hover:bg-gray-50 transition-all duration-300 hover:border-rose-500">
                    <div className="w-10 h-10 bg-gradient-to-r from-emerald-500 to-teal-500 rounded-lg flex items-center justify-center transform group-hover:scale-110 transition-transform duration-300">
                      <Gift className="text-white" size={20} />
                    </div>
                    <span>Gift Dedication</span>
                  </button>
                </div>
              </div>

              <button className="w-full bg-gradient-to-r from-rose-500 to-purple-500 hover:from-rose-600 hover:to-purple-600 text-white py-4 rounded-lg font-semibold transition-all duration-300 transform hover:scale-[1.02] hover:shadow-lg flex items-center justify-center gap-2">
                Complete Donation
                <Heart className="animate-pulse" size={20} />
              </button>

              <p className="text-sm text-gray-600 text-center">
                Your donation is secure and encrypted. You can find our privacy policy here.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}