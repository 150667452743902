import React from 'react';
import { BookOpen, Download, FileText, Users, Database, Newspaper, BarChart as ChartBar, ExternalLink } from 'lucide-react';
import Header from '../components/Header';
import NewsletterSubscription from '../components/NewsletterSubscription';
import ArticlesSection from '../components/resources/ArticlesSection';
import Footer from '../components/Footer';

const publications = [
  {
    category: 'White Papers',
    items: [
      {
        title: 'Impact of Early Education Interventions',
        description: 'A comprehensive study on the long-term effects of early childhood education programs in Uganda.',
        date: 'March 2024',
        authors: 'Dr. Sarah Nambi, Dr. John Okello',
        type: 'PDF',
        size: '2.8 MB'
      },
      {
        title: 'Community Health Initiatives: 5-Year Analysis',
        description: 'Detailed analysis of health outcomes from our community-based healthcare programs.',
        date: 'January 2024',
        authors: 'Dr. Michael Wako, Dr. Emily Chen',
        type: 'PDF',
        size: '3.2 MB'
      }
    ]
  },
  {
    category: 'Research Reports',
    items: [
      {
        title: 'Clean Water Access: Rural Communities',
        description: 'Statistical analysis of water accessibility improvements in rural Uganda (2020-2024).',
        date: 'February 2024',
        authors: 'Research Team',
        type: 'PDF',
        size: '4.1 MB'
      },
      {
        title: 'Educational Outcomes Report 2024',
        description: 'Annual report on student performance and program effectiveness metrics.',
        date: 'March 2024',
        authors: 'Education Department',
        type: 'PDF',
        size: '2.5 MB'
      }
    ]
  }
];

const datasets = [
  {
    title: 'Education Program Metrics',
    description: 'Raw data from our education initiatives including enrollment rates, academic performance, and graduation rates.',
    format: 'CSV, Excel',
    lastUpdated: 'March 2024',
    size: '1.2 GB'
  },
  {
    title: 'Healthcare Impact Data',
    description: 'Comprehensive dataset of health outcomes, treatment efficacy, and community health indicators.',
    format: 'CSV, JSON',
    lastUpdated: 'February 2024',
    size: '850 MB'
  }
];

const researchArticles = [
  {
    title: 'Transforming Rural Education: A Data-Driven Approach',
    excerpt: 'Our latest research reveals how targeted interventions have improved educational outcomes in rural communities.',
    image: 'https://images.unsplash.com/photo-1427504494785-3a9ca7044f45?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80',
    author: 'Dr. Sarah Nambi',
    readTime: '15 min read',
    category: 'Education Research',
    cta: {
      text: 'Read Full Paper',
      action: '/research/rural-education'
    }
  },
  {
    title: 'Community Health Programs: 5-Year Impact Study',
    excerpt: 'Comprehensive analysis of our healthcare initiatives and their impact on community well-being.',
    image: 'https://images.unsplash.com/photo-1576091160550-2173dba999ef?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80',
    author: 'Dr. Michael Wako',
    readTime: '20 min read',
    category: 'Healthcare Research',
    cta: {
      text: 'View Research',
      action: '/research/health-impact'
    }
  },
  {
    title: 'Sustainable Development: Evidence-Based Strategies',
    excerpt: 'Key findings from our long-term study on effective community development approaches.',
    image: 'https://images.unsplash.com/photo-1491336477066-31156b5e4f35?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80',
    author: 'Dr. John Okello',
    readTime: '18 min read',
    category: 'Development Research',
    cta: {
      text: 'Access Report',
      action: '/research/sustainable-development'
    }
  }
];

export default function ResearchPublications() {
  return (
    <div className="min-h-screen">
      <Header />
      
      <main className="pt-20">
        {/* Hero Section */}
        <section className="relative h-[60vh] flex items-center">
          <div className="absolute inset-0 z-0">
            <img
              src="https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-4.0.3&auto=format&fit=crop&w=2340&q=80"
              alt="Research and collaboration"
              className="w-full h-full object-cover"
            />
            <div className="absolute inset-0 bg-black/50" />
          </div>
          
          <div className="container mx-auto px-4 relative z-10">
            <div className="max-w-3xl text-white">
              <h1 className="text-5xl md:text-6xl font-bold mb-6">
                Evidence-Based Insights & Publications
              </h1>
              <p className="text-xl md:text-2xl text-gray-200">
                Explore our research, publications, and datasets that drive positive change through data-driven decision making.
              </p>
            </div>
          </div>
        </section>

        {/* Methodology Section */}
        <section className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto">
              <div className="text-center mb-12">
                <h2 className="text-4xl font-bold mb-6">Our Research Methodology</h2>
                <p className="text-lg text-gray-600">
                  We maintain the highest standards of data collection, analysis, and verification to ensure our research provides accurate and actionable insights.
                </p>
              </div>

              <div className="grid md:grid-cols-2 gap-8">
                <div className="bg-gray-50 rounded-xl p-6">
                  <div className="flex items-start gap-4">
                    <div className="w-12 h-12 bg-gray-900 rounded-lg flex items-center justify-center shrink-0">
                      <ChartBar className="text-white" size={24} />
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-2">Data Collection</h3>
                      <p className="text-gray-600">
                        Rigorous methodologies ensuring comprehensive and accurate data gathering from multiple sources.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-50 rounded-xl p-6">
                  <div className="flex items-start gap-4">
                    <div className="w-12 h-12 bg-gray-900 rounded-lg flex items-center justify-center shrink-0">
                      <Users className="text-white" size={24} />
                    </div>
                    <div>
                      <h3 className="text-xl font-semibold mb-2">Peer Review</h3>
                      <p className="text-gray-600">
                        Expert validation and review process ensuring the highest standards of research quality.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Publications Section */}
        <section className="py-20 bg-gray-50">
          <div className="container mx-auto px-4">
            <div className="max-w-5xl mx-auto">
              <div className="text-center mb-12">
                <h2 className="text-4xl font-bold mb-6">Publications</h2>
                <p className="text-lg text-gray-600">
                  Access our latest research papers, reports, and policy briefs.
                </p>
              </div>

              {publications.map((section, index) => (
                <div key={index} className="mb-12">
                  <h3 className="text-2xl font-bold mb-6">{section.category}</h3>
                  <div className="grid md:grid-cols-2 gap-6">
                    {section.items.map((item, idx) => (
                      <div key={idx} className="bg-white rounded-xl shadow-lg p-6">
                        <div className="flex items-start gap-4">
                          <div className="w-12 h-12 bg-gray-900 rounded-lg flex items-center justify-center shrink-0">
                            <FileText className="text-white" size={24} />
                          </div>
                          <div>
                            <h4 className="text-xl font-semibold mb-2">{item.title}</h4>
                            <p className="text-gray-600 mb-4">{item.description}</p>
                            <div className="flex items-center gap-4 text-sm text-gray-600 mb-4">
                              <span>Published: {item.date}</span>
                              <span>Size: {item.size}</span>
                            </div>
                            <button className="flex items-center gap-2 text-gray-900 font-semibold hover:text-gray-700 transition-colors">
                              Download {item.type}
                              <Download size={20} />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Datasets Section */}
        <section className="py-20 bg-white">
          <div className="container mx-auto px-4">
            <div className="max-w-5xl mx-auto">
              <div className="text-center mb-12">
                <h2 className="text-4xl font-bold mb-6">Open Data & Datasets</h2>
                <p className="text-lg text-gray-600">
                  Access our research data for academic use and further analysis.
                </p>
              </div>

              <div className="grid md:grid-cols-2 gap-8">
                {datasets.map((dataset, index) => (
                  <div key={index} className="bg-gray-50 rounded-xl p-6">
                    <div className="flex items-start gap-4">
                      <div className="w-12 h-12 bg-gray-900 rounded-lg flex items-center justify-center shrink-0">
                        <Database className="text-white" size={24} />
                      </div>
                      <div>
                        <h3 className="text-xl font-semibold mb-2">{dataset.title}</h3>
                        <p className="text-gray-600 mb-4">{dataset.description}</p>
                        <div className="flex items-center gap-4 text-sm text-gray-600 mb-4">
                          <span>Format: {dataset.format}</span>
                          <span>Updated: {dataset.lastUpdated}</span>
                          <span>Size: {dataset.size}</span>
                        </div>
                        <button className="flex items-center gap-2 text-gray-900 font-semibold hover:text-gray-700 transition-colors">
                          Access Dataset
                          <ExternalLink size={20} />
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Press Kit Section */}
        <section className="py-20 bg-gray-50">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-4xl font-bold mb-6">Media & Press Kit</h2>
              <p className="text-lg text-gray-600 mb-8">
                Access our media resources, high-resolution images, and press releases.
              </p>
              <button className="bg-gray-900 text-white px-8 py-3 rounded-full font-semibold hover:bg-gray-800 transition-colors flex items-center gap-2 mx-auto">
                Download Press Kit
                <Newspaper size={20} />
              </button>
            </div>
          </div>
        </section>

        {/* Featured Research Articles */}
        <ArticlesSection
          title="Featured Research & Insights"
          description="Explore our latest research findings and their impact on communities."
          articles={researchArticles}
        />

        {/* Collaboration CTA */}
        <section className="py-20 bg-gray-900 text-white">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-4xl font-bold mb-6">Partner With Us</h2>
              <p className="text-xl opacity-90 mb-8">
                Interested in collaborating on research projects? We're always looking to partner with academic institutions and research organizations.
              </p>
              <button className="bg-white text-gray-900 px-8 py-3 rounded-full font-semibold hover:bg-gray-100 transition-colors">
                Explore Research Partnerships
              </button>
            </div>
          </div>
        </section>

        <NewsletterSubscription />
      </main>

      <Footer />
    </div>
  );
}