import React from 'react';
import { Heart } from 'lucide-react';

export default function DonateCTA() {
  return (
    <section className="relative py-20 overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900 to-rose-900">
        <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:16px_16px]" />
      </div>

      <div className="container relative mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-6 text-white animate-fade-in">
            Join Us in Making a Difference
          </h2>
          <p className="text-xl text-gray-300 mb-8 animate-fade-in">
            Your generosity today creates lasting change for generations to come. Every donation, no matter the size, helps us continue our mission of transforming lives and building stronger communities.
          </p>
          <button 
            onClick={() => document.getElementById('donation-options')?.scrollIntoView({ behavior: 'smooth' })}
            className="group bg-white text-gray-900 px-8 py-3 rounded-full font-semibold flex items-center gap-2 mx-auto hover:bg-gray-100 transition-all duration-300 transform hover:scale-105 hover:shadow-xl"
          >
            Donate Now
            <Heart className="text-rose-500 transform group-hover:scale-110 transition-transform" size={20} />
          </button>
          <p className="mt-8 text-sm text-gray-400">
            The Prince Wako Foundation is a registered non-profit organization. All donations are tax-deductible.
          </p>
        </div>
      </div>

      {/* Animated background elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute top-0 right-0 w-1/2 h-1/2 bg-gradient-to-b from-rose-500/20 to-transparent blur-3xl animate-blob" />
        <div className="absolute bottom-0 left-0 w-1/2 h-1/2 bg-gradient-to-t from-purple-500/20 to-transparent blur-3xl animate-blob animation-delay-2000" />
      </div>
    </section>
  );
}