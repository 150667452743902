import React from 'react';
import { Mail, MessageCircle, Phone } from 'lucide-react';

const contactMethods = [
  {
    icon: Mail,
    title: 'Email Us',
    description: 'Get a response within 24 hours',
    gradient: 'from-pink-500 to-rose-500'
  },
  {
    icon: Phone,
    title: 'Call Us',
    description: 'Available Mon-Fri, 9am-5pm',
    gradient: 'from-blue-500 to-indigo-500'
  },
  {
    icon: MessageCircle,
    title: 'Live Chat',
    description: 'Chat with our support team',
    gradient: 'from-emerald-500 to-teal-500'
  }
];

export default function ContactHero() {
  return (
    <div className="relative min-h-[90vh] flex items-center overflow-hidden pt-20 pb-16">
      <div className="absolute inset-0 z-0">
        <img
          src="https://efvuswbeeeiipabzoqsw.supabase.co/storage/v1/object/public/pub-imgs/home-pw-media/prince-wako-volunteers-with-organization-tshirts.jpg"
          alt="Team collaboration"
          className="w-full h-full object-cover transform scale-105 animate-ken-burns"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-gray-900/90 to-indigo-900/70" />
      </div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-3xl animate-fade-in space-y-12">
          <div className="space-y-6">
            <h1 className="text-5xl md:text-6xl font-bold text-white leading-tight">
              We'd Love to{' '}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-300 to-purple-300 block md:inline">
                Hear from You!
              </span>
            </h1>
            <p className="text-xl md:text-2xl text-gray-200 leading-relaxed">
              Have questions about our initiatives? Want to get involved? We're here to help and would love to connect with you.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-6 pb-8">
            {contactMethods.map((method, index) => (
              <div 
                key={index}
                className="group bg-white/10 backdrop-blur-sm rounded-xl p-6 hover:bg-white/20 transition-all duration-300 transform hover:-translate-y-1"
              >
                <div className={`w-12 h-12 mb-4 bg-gradient-to-r ${method.gradient} rounded-lg flex items-center justify-center transform group-hover:scale-110 transition-transform duration-300`}>
                  <method.icon className="text-white" size={24} />
                </div>
                <h3 className="text-lg font-semibold text-white mb-2">{method.title}</h3>
                <p className="text-gray-300">{method.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Animated background shapes */}
      <div className="absolute inset-0 z-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-40 -right-40 w-80 h-80 bg-purple-500/10 rounded-full blur-3xl animate-blob" />
        <div className="absolute -bottom-40 -left-40 w-80 h-80 bg-blue-500/10 rounded-full blur-3xl animate-blob animation-delay-2000" />
      </div>
    </div>
  );
}