import { MapPin, Navigation } from 'lucide-react';

const locations = [
  { 
    name: 'Kampala Education Center',
    region: 'Central Region',
    description: 'Our flagship education facility serving over 1,000 students',
    gradient: 'from-blue-500 to-indigo-600'
  },
  { 
    name: 'Gulu Community Clinic',
    region: 'Northern Region',
    description: 'Healthcare services for rural communities',
    gradient: 'from-rose-500 to-pink-600'
  },
  { 
    name: 'Jinja Water Project',
    region: 'Eastern Region',
    description: 'Clean water access for 5,000+ residents',
    gradient: 'from-amber-500 to-orange-600'
  },
  { 
    name: 'Mbarara Agricultural Initiative',
    region: 'Western Region',
    description: 'Sustainable farming education and support',
    gradient: 'from-emerald-500 to-teal-600'
  }
];

export default function InitiativesMap() {
  return (
    <section className="py-20 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
            Where We Work
          </h2>
          <p className="text-lg text-gray-600">
            Our initiatives span across Uganda, creating impact in multiple regions.
          </p>
        </div>

        <div className="bg-white rounded-xl shadow-xl p-8 relative overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-br from-indigo-50 to-purple-50 opacity-50 pointer-events-none rounded-xl" />

          <div className="relative">
            <h3 className="text-xl font-semibold mb-6 flex items-center gap-2">
              <Navigation className="text-indigo-600" size={24} />
              Project Locations
            </h3>

            {/* Two-column layout for cards */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {locations.map((location, index) => (
                <div 
                  key={index}
                  className="group bg-white rounded-xl p-6 shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1"
                >
                  <div className="flex items-start gap-4">
                    <div className={`w-12 h-12 bg-gradient-to-r ${location.gradient} rounded-lg flex items-center justify-center shrink-0 transform group-hover:scale-110 transition-transform duration-300`}>
                      <MapPin className="text-white" size={24} />
                    </div>
                    <div>
                      <h4 className="font-semibold group-hover:text-indigo-600 transition-colors duration-300">
                        {location.name}
                      </h4>
                      <p className="text-gray-600 text-sm mb-1">{location.region}</p>
                      <p className="text-gray-500 text-sm">{location.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}
