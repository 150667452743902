import React from 'react';
import { Users, Gift, Building2, HandHeart } from 'lucide-react';

const alternatives = [
  {
    icon: Users,
    title: 'Start a Fundraiser',
    description: 'Create your own fundraising campaign to support our cause.',
    cta: 'Start Now',
    gradient: 'from-blue-500 to-indigo-600'
  },
  {
    icon: Gift,
    title: 'Legacy Giving',
    description: 'Make a lasting impact through planned giving and wills.',
    cta: 'Learn More',
    gradient: 'from-rose-500 to-pink-600'
  },
  {
    icon: Building2,
    title: 'Corporate Partnerships',
    description: 'Partner with us to create meaningful social impact.',
    cta: 'Partner With Us',
    gradient: 'from-amber-500 to-orange-600'
  },
  {
    icon: HandHeart,
    title: 'Donate Goods',
    description: 'Support our work through in-kind donations.',
    cta: 'See Needs',
    gradient: 'from-emerald-500 to-teal-600'
  }
];

export default function AlternativeDonations() {
  return (
    <section className="py-20 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-rose-600 to-purple-600 bg-clip-text text-transparent">
            Other Ways to Give
          </h2>
          <p className="text-lg text-gray-600">
            Discover different ways you can support our mission beyond monetary donations.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {alternatives.map((alt, index) => (
            <div 
              key={index} 
              className="group bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
            >
              <div className={`w-16 h-16 bg-gradient-to-r ${alt.gradient} rounded-full flex items-center justify-center mx-auto mb-6 transform group-hover:scale-110 transition-transform duration-300`}>
                <alt.icon className="text-white" size={32} />
              </div>
              <h3 className="text-xl font-semibold mb-3 text-center group-hover:text-rose-600 transition-colors duration-300">
                {alt.title}
              </h3>
              <p className="text-gray-600 mb-6 text-center group-hover:text-gray-700 transition-colors duration-300">
                {alt.description}
              </p>
              <button className="w-full bg-gradient-to-r from-gray-800 to-gray-900 hover:from-gray-900 hover:to-black text-white px-6 py-3 rounded-full font-semibold transform group-hover:scale-105 transition-all duration-300 shadow-md hover:shadow-lg">
                {alt.cta}
              </button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}