import { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import App from './App.tsx';
import CommunityImpact from './pages/CommunityImpact.tsx';
import About from './pages/About.tsx';
import Initiatives from './pages/Initiatives.tsx';
import Contact from './pages/Contact.tsx';
import Donate from './pages/Donate.tsx';
import StudentResources from './pages/StudentResources.tsx';
import ParentResources from './pages/ParentResources.tsx';
import ResearchPublications from './pages/ResearchPublications.tsx';
import './index.css';

function ScrollToTop() {
  const location = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}

function App_WithScrollToTop() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/impact" element={<CommunityImpact />} />
        <Route path="/about" element={<About />} />
        <Route path="/initiatives" element={<Initiatives />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/resources/students" element={<StudentResources />} />
        <Route path="/resources/parents" element={<ParentResources />} />
        <Route path="/resources/research" element={<ResearchPublications />} />
      </Routes>
    </Router>
  );
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App_WithScrollToTop />
  </StrictMode>
);