import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Header from '../components/Header';
import ContactHero from '../components/contact/ContactHero';
import ContactInfo from '../components/contact/ContactInfo';
import ContactForm from '../components/contact/ContactForm';
// import ContactMap from '../components/contact/ContactMap';
import ContactFAQ from '../components/contact/ContactFAQ';
import NewsletterSubscription from '../components/NewsletterSubscription';
import Footer from '../components/Footer';

export default function Contact() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        // Scroll after a slight delay to ensure layout is rendered
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    }
  }, [location]);

  return (
    <div className="min-h-screen relative">
      <Header />
      <main className="relative z-0">
        <ContactHero />
        <ContactInfo />
        <ContactForm />
        {/* <ContactMap /> */}
        <ContactFAQ />
        <NewsletterSubscription />
      </main>
      <Footer />
    </div>
  );
}
