import { Brain, Heart, Star, Sun } from 'lucide-react';

export default function AboutStory() {
  return (
    <section className="py-20 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-center bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
          Our Story
        </h2>

        <div className="max-w-5xl mx-auto text-center">
          <p className="text-lg text-gray-600 mb-10 animate-fade-in">
            The Prince Wako Foundation is a Uganda-based NGO driven by young people focused on empowering vulnerable girls and their communities through education, healthcare, environmental sustainability, and economic development.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 mb-16">
          <div className="group bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
            <div className="w-16 h-16 bg-gradient-to-r from-pink-500 to-rose-500 rounded-full flex items-center justify-center mx-auto mb-4 transform group-hover:scale-110 transition-transform duration-300">
              <Heart className="text-white" size={32} />
            </div>
            <h3 className="font-semibold mb-2 text-center">Compassion</h3>
            <p className="text-gray-600 text-center">We lead with empathy and understanding in all our initiatives.</p>
          </div>

          <div className="group bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
            <div className="w-16 h-16 bg-gradient-to-r from-red-500 to-rose-500 rounded-full flex items-center justify-center mx-auto mb-4 transform group-hover:scale-110 transition-transform duration-300">
              <Brain className="text-white" size={32} />
            </div>
            <h3 className="font-semibold mb-2 text-center">Integrity</h3>
            <p className="text-gray-600 text-center">We lead with integrity in all endeavours.</p>
          </div>

          <div className="group bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
            <div className="w-16 h-16 bg-gradient-to-r from-amber-500 to-orange-500 rounded-full flex items-center justify-center mx-auto mb-4 transform group-hover:scale-110 transition-transform duration-300">
              <Star className="text-white" size={32} />
            </div>
            <h3 className="font-semibold mb-2 text-center">Excellence</h3>
            <p className="text-gray-600 text-center">We strive for the highest standards in our programs and services.</p>
          </div>

          <div className="group bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1">
            <div className="w-16 h-16 bg-gradient-to-r from-emerald-500 to-teal-500 rounded-full flex items-center justify-center mx-auto mb-4 transform group-hover:scale-110 transition-transform duration-300">
              <Sun className="text-white" size={32} />
            </div>
            <h3 className="font-semibold mb-2 text-center">Sustainability</h3>
            <p className="text-gray-600 text-center">We create lasting impact through sustainable development.</p>
          </div>
        </div>

        <div className="max-w-5xl mx-auto text-center">
          <p className="text-lg text-gray-600 animate-fade-in">
            Founded in 2017 by Joshua Wako and Ashley Mugabi to address period poverty, inequality, and lack of access to education and healthcare in rural Ugandan communities. Inspired by the founders’ personal experiences and a commitment to uplifting marginalized groups, particularly women, youth, and children.
          </p>
        </div>
      </div>
    </section>
  );
}
