import { Link } from 'react-router-dom';

export default function Hero() {
  return (
    <div className="relative min-h-[90vh] flex items-center overflow-hidden pt-20 pb-16">
      <div className="absolute inset-0 z-0">
        <img
          src="https://efvuswbeeeiipabzoqsw.supabase.co/storage/v1/object/public/pub-imgs/home-pw-media/prince-wako-cover-img.jpg"
          alt="Children smiling"
          className="w-full h-full object-cover transform scale-105 animate-ken-burns"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-indigo-900/90 to-purple-900/70" />
      </div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-3xl animate-fade-in-up space-y-12">
          <div className="space-y-6">
            <h1 className="text-5xl md:text-7xl font-bold text-white leading-tight">
              Building a{' '}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-pink-300 to-purple-300">
                Brighter Future
              </span>{' '}
              Together
            </h1>
            <p className="text-xl md:text-2xl text-gray-200 leading-relaxed">
              Empowering the girl child and communities through education, healthcare, and sustainable environmental and economic development initiatives.
            </p>
          </div>
          
          <div className="flex flex-wrap gap-6 pb-8">
            <Link
              to="/contact"
              className="bg-indigo-600 text-white px-6 py-3 rounded-full font-semibold hover:bg-indigo-700 transition-colors"
            >
              Get Involved
            </Link>

            <Link
              to="/about"
              className="bg-gray-100 text-gray-800 px-6 py-3 rounded-full font-semibold hover:bg-gray-200 transition-colors"
            >
              Learn More
            </Link>
          </div>
        </div>
      </div>

      {/* Animated background shapes */}
      <div className="absolute inset-0 z-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-40 -right-40 w-80 h-80 bg-purple-500/10 rounded-full blur-3xl animate-blob" />
        <div className="absolute -bottom-40 -left-40 w-80 h-80 bg-pink-500/10 rounded-full blur-3xl animate-blob animation-delay-2000" />
      </div>
    </div>
  );
}
