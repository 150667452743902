import React from 'react';
import { Link } from 'react-router-dom';
import { HandHeart, Users, DollarSign } from 'lucide-react';

const actions = [
  {
    icon: HandHeart,
    title: "Volunteer",
    description: "Join our team of dedicated volunteers making a difference.",
    color: "from-emerald-500 to-teal-600"
  },
  {
    icon: Users,
    title: "Partner With Us",
    description: "Collaborate with us to amplify our impact.",
    color: "from-blue-500 to-indigo-600"
  },
  {
    icon: DollarSign,
    title: "Donate",
    description: "Support our initiatives with a donation.",
    color: "from-purple-500 to-pink-600"
  }
];

export default function CallToAction() {
  return (
    <section className="relative py-20 overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900 to-indigo-900">
        <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:16px_16px]" />
      </div>

      <div className="container relative mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold mb-6 text-white animate-fade-in">
            Join Our Mission
          </h2>
          <p className="text-xl text-gray-300 animate-fade-in">
            Together, we can make a lasting difference in communities around the world.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {actions.map((action, index) => (
            <div 
              key={index}
              className="group relative bg-white/10 backdrop-blur-sm rounded-xl p-8 hover:bg-white/20 transition-all duration-300 transform hover:-translate-y-1"
            >
              <div className={`w-16 h-16 bg-gradient-to-r ${action.color} rounded-full flex items-center justify-center mx-auto mb-4 transform group-hover:scale-110 transition-transform duration-300`}>
                <action.icon className="text-white" size={32} />
              </div>
              <h3 className="text-xl font-semibold mb-3 text-white text-center">
                {action.title}
              </h3>
              <p className="text-gray-300 mb-6 text-center">
                {action.description}
              </p>
              <Link
                to="/contact#contact-form"
                className="w-full bg-white text-gray-900 px-6 py-3 rounded-full font-semibold transform group-hover:scale-105 transition-all duration-300 hover:shadow-lg text-center block"
              >
                Get Started
              </Link>
            </div>
          ))}
        </div>
      </div>

      {/* Animated background elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute top-0 right-0 w-1/2 h-1/2 bg-gradient-to-b from-purple-500/20 to-transparent blur-3xl animate-blob" />
        <div className="absolute bottom-0 left-0 w-1/2 h-1/2 bg-gradient-to-t from-blue-500/20 to-transparent blur-3xl animate-blob animation-delay-2000" />
      </div>
    </section>
  );
}