import { Linkedin, Twitter } from 'lucide-react';

const leaders = [
  {
    name: 'Joshua Wako',
    role: 'Founder & Executive Director',
    image: '',
    bio: 'Visionary leader with over 15 years of experience in community development and social impact initiatives.',
    social: {
      linkedin: '#',
      twitter: '#'
    }
  },
  {
    name: 'Ashley Mugabi',
    role: 'Co-Founder',
    image: '',
    bio: 'Expert in educational program development with a passion for creating sustainable learning opportunities.',
    social: {
      linkedin: '#',
      twitter: '#'
    }
  },
];

export default function Leadership() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold mb-6">Our Leadership</h2>
          <p className="text-lg text-gray-600">
            Meet the dedicated team driving our mission forward and creating lasting impact in communities.
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          {leaders.map((leader, index) => (
            <div key={index} className="bg-gray-50 rounded-xl p-6">
              {/* <div className="mb-6">
                <img
                  src={leader.image}
                  alt={leader.name}
                  className="w-32 h-32 rounded-full mx-auto object-cover"
                />
              </div> */}
              <div className="text-center">
                <h3 className="text-xl font-semibold mb-2">{leader.name}</h3>
                <p className="text-gray-600 mb-3">{leader.role}</p>
                <p className="text-gray-600 mb-4">{leader.bio}</p>
                <div className="flex justify-center gap-4">
                  <a
                    href={leader.social.linkedin}
                    className="text-gray-600 hover:text-gray-900 transition-colors"
                  >
                    <Linkedin size={20} />
                  </a>
                  <a
                    href={leader.social.twitter}
                    className="text-gray-600 hover:text-gray-900 transition-colors"
                  >
                    <Twitter size={20} />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}