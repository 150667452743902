import React from 'react';
import { Link } from 'react-router-dom';
import { Heart, Users, HandHeart } from 'lucide-react';

export default function AboutCTA() {
  return (
    <section className="relative py-20 overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-gray-900 to-indigo-900">
        <div className="absolute inset-0 bg-grid-white/[0.05] bg-[size:16px_16px]" />
      </div>

      <div className="container relative mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold mb-6 text-white animate-fade-in">
            Join Our Mission
          </h2>
          <p className="text-xl text-gray-300 animate-fade-in">
            Together, we can create lasting change and build stronger communities.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          <div className="group relative bg-white/10 backdrop-blur-sm rounded-xl p-8 hover:bg-white/20 transition-all duration-300 transform hover:-translate-y-1">
            <div className="w-16 h-16 bg-gradient-to-r from-pink-500 to-rose-500 rounded-full flex items-center justify-center mx-auto mb-4 transform group-hover:scale-110 transition-transform duration-300">
              <HandHeart className="text-white" size={32} />
            </div>
            <h3 className="text-xl font-semibold mb-3 text-white text-center">Make a Donation</h3>
            <p className="text-gray-300 mb-4 text-center">
              Support our initiatives with a one-time or monthly donation.
            </p>
            <Link
              to="/contact#contact-form"
              className="w-full block text-center bg-white text-gray-900 px-6 py-2 rounded-full font-semibold hover:bg-gray-100 transition-colors"
            >
              Donate Now
            </Link>
          </div>

          <div className="group relative bg-white/10 backdrop-blur-sm rounded-xl p-8 hover:bg-white/20 transition-all duration-300 transform hover:-translate-y-1">
            <div className="w-16 h-16 bg-gradient-to-r from-blue-500 to-indigo-500 rounded-full flex items-center justify-center mx-auto mb-4 transform group-hover:scale-110 transition-transform duration-300">
              <Users className="text-white" size={32} />
            </div>
            <h3 className="text-xl font-semibold mb-3 text-white text-center">Volunteer With Us</h3>
            <p className="text-gray-300 mb-4 text-center">
              Join our team of dedicated volunteers making a difference.
            </p>
            <Link
              to="/contact#contact-form"
              className="w-full block text-center bg-white text-gray-900 px-6 py-2 rounded-full font-semibold hover:bg-gray-100 transition-colors"
            >
             Get Started
            </Link>
          </div>

          <div className="group relative bg-white/10 backdrop-blur-sm rounded-xl p-8 hover:bg-white/20 transition-all duration-300 transform hover:-translate-y-1">
            <div className="w-16 h-16 bg-gradient-to-r from-emerald-500 to-teal-500 rounded-full flex items-center justify-center mx-auto mb-4 transform group-hover:scale-110 transition-transform duration-300">
              <Heart className="text-white" size={32} />
            </div>
            <h3 className="text-xl font-semibold mb-3 text-white text-center">Partner With Us</h3>
            <p className="text-gray-300 mb-4 text-center">
              Collaborate with us to amplify our impact in communities.
            </p>
            <Link
              to="/contact#contact-form"
              className="w-full block text-center bg-white text-gray-900 px-6 py-2 rounded-full font-semibold hover:bg-gray-100 transition-colors"
            >
              Learn more
            </Link>
          </div>
        </div>
      </div>

      {/* Animated background elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute top-0 right-0 w-1/2 h-1/2 bg-gradient-to-b from-purple-500/20 to-transparent blur-3xl animate-blob" />
        <div className="absolute bottom-0 left-0 w-1/2 h-1/2 bg-gradient-to-t from-blue-500/20 to-transparent blur-3xl animate-blob animation-delay-2000" />
      </div>
    </section>
  );
}