import { GraduationCap, Droplet, Users, Heart, Globe, Target } from 'lucide-react';

const metrics = [
  {
    icon: GraduationCap,
    label: 'Students Supported',
    value: '500+',
    description: 'Benefiting from scholarships and education programs',
    gradient: 'from-blue-500 to-indigo-600'
  },
  {
    icon: Droplet,
    label: 'Clean Water Projects',
    value: '5+',
    description: 'Providing access to safe and clean water',
    gradient: 'from-cyan-500 to-blue-600'
  },
  {
    icon: Users,
    label: 'Communities Served',
    value: '20+',
    description: 'Across Kampala, Kaliro, Jinja, and beyond',
    gradient: 'from-purple-500 to-indigo-600'
  },
  {
    icon: Heart,
    label: 'Volunteers Engaged',
    value: '200+',
    description: 'Empowering impact through local and global support',
    gradient: 'from-rose-500 to-pink-600'
  },
  {
    icon: Target,
    label: 'Projects In Progress',
    value: '3+',
    description: 'Major initiatives currently being developed',
    gradient: 'from-amber-500 to-orange-600'
  },
  {
    icon: Globe,
    label: 'Lives Impacted',
    value: '10,000+',
    description: 'Transformed through education, health, and empowerment',
    gradient: 'from-emerald-500 to-teal-600'
  }
];

export default function ImpactMetrics() {
  return (
    <section className="py-20 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
            Our Impact in Numbers
          </h2>
          <p className="text-lg text-gray-600">
            Real-time metrics showcasing the tangible difference we're making in communities.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {metrics.map((metric, index) => (
            <div 
              key={index} 
              className="group bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
            >
              <div className={`w-16 h-16 bg-gradient-to-r ${metric.gradient} rounded-full flex items-center justify-center mx-auto mb-6 transform group-hover:scale-110 transition-transform duration-300`}>
                <metric.icon className="text-white" size={32} />
              </div>
              <div className="text-center">
                <div className="text-3xl font-bold mb-2 bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
                  {metric.value}
                </div>
                <div className="text-xl font-semibold mb-2 group-hover:text-indigo-600 transition-colors duration-300">
                  {metric.label}
                </div>
                <p className="text-gray-600 group-hover:text-gray-700 transition-colors duration-300">
                  {metric.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}