import React from 'react';
import { Heart, Users, HandHeart } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function InitiativesCTA() {
  return (
    <section className="py-20 bg-gray-900 text-white">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold mb-6">Support Our Initiatives</h2>
          <p className="text-xl opacity-90">
            Join us in creating lasting positive change in communities across Uganda.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          <div className="text-center">
            <div className="w-16 h-16 bg-white/10 rounded-full flex items-center justify-center mx-auto mb-4">
              <HandHeart className="text-white" size={32} />
            </div>
            <h3 className="text-xl font-semibold mb-3">Make a Donation</h3>
            <p className="opacity-90 mb-4">
              Your contribution helps fund our community initiatives.
            </p>
            <Link to="/contact#contact-form" className="bg-white text-gray-900 px-6 py-2 rounded-full font-semibold hover:bg-gray-100 transition-colors">
              Donate Now
            </Link>
          </div>

          <div className="text-center">
            <div className="w-16 h-16 bg-white/10 rounded-full flex items-center justify-center mx-auto mb-4">
              <Users className="text-white" size={32} />
            </div>
            <h3 className="text-xl font-semibold mb-3">Volunteer</h3>
            <p className="opacity-90 mb-4">
              Join our team and help make a difference in communities.
            </p>
            <Link to="/contact#contact-form" className="bg-white text-gray-900 px-6 py-2 rounded-full font-semibold hover:bg-gray-100 transition-colors">
              Get Involved
            </Link>
          </div>

          <div className="text-center">
            <div className="w-16 h-16 bg-white/10 rounded-full flex items-center justify-center mx-auto mb-4">
              <Heart className="text-white" size={32} />
            </div>
            <h3 className="text-xl font-semibold mb-3">Partner With Us</h3>
            <p className="opacity-90 mb-4">
              Collaborate with us to expand our impact together.
            </p>
            <Link to="/contact#contact-form" className="bg-white text-gray-900 px-6 py-2 rounded-full font-semibold hover:bg-gray-100 transition-colors">
              Learn More
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}