import { School, Heart, Home } from 'lucide-react';
import { Link } from 'react-router-dom';

const projects = [
  {
    icon: School,
    name: "Building Schools for Future Women Leaders",
    location: "Kaliro District, Uganda",
    status: "In Progress",
    progress: 2,
    fundingGoal: 50000,
    fundingCurrent: 0,
    impact: "Fundraising to support 100+ girls"
  },
  {
    icon: Heart,
    name: "Healthcare Access Initiative",
    location: "Jinja District, Uganda",
    status: "Fundraising",
    progress: 0,
    fundingGoal: 40000,
    fundingCurrent: 0,
    impact: "2 community clinics planned"
  },
  {
    icon: Home,
    name: "Sustainable Housing Project",
    location: "Iganga District, Uganda",
    status: "In Progress",
    progress: 0,
    fundingGoal: 75000,
    fundingCurrent: 0,
    impact: "10+ homes under construction"
  }
];

export default function OngoingProjects() {
  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold mb-6">Featured Projects</h2>
          <p className="text-lg text-gray-600">
            Track our ongoing initiatives and see how your support is creating real change.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {projects.map((project, index) => (
            <div key={index} className="bg-gray-50 rounded-xl p-6 shadow-md hover:shadow-lg transition">
              <div className="flex items-center gap-4 mb-4">
                <div className="w-12 h-12 bg-gray-900 rounded-lg flex items-center justify-center">
                  <project.icon className="text-white" size={24} />
                </div>
                <div>
                  <h3 className="font-semibold">{project.name}</h3>
                  <p className="text-sm text-gray-600">{project.location}</p>
                </div>
              </div>

              <div className="mb-4">
                <div className="flex justify-between text-sm mb-2">
                  <span className={`px-2 py-1 rounded-full ${
                    project.status === 'In Progress' ? 'bg-green-100 text-green-800' :
                    project.status === 'Fundraising' ? 'bg-yellow-100 text-yellow-800' :
                    'bg-blue-100 text-blue-800'
                  }`}>
                    {project.status}
                  </span>
                  <span className="text-gray-600">{project.progress}%</span>
                </div>
                <div className="w-full h-2 bg-gray-200 rounded-full">
                  <div 
                    className="h-full bg-gray-900 rounded-full"
                    style={{ width: `${project.progress}%` }}
                  />
                </div>
              </div>

              <div className="mb-4">
                <div className="flex justify-between text-sm mb-1">
                  <span className="text-gray-600">Raised</span>
                  <span className="font-semibold">
                    ${project.fundingCurrent.toLocaleString()} / ${project.fundingGoal.toLocaleString()}
                  </span>
                </div>
                <p className="text-sm text-gray-600">{project.impact}</p>
              </div>
              <Link
                to="/contact#contact-form"
                className="w-full text-center bg-gray-900 text-white px-5 py-3 rounded-full font-semibold hover:bg-gray-800 transition-all duration-300"
              >
                Support This Project
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
