import React, { useState } from 'react';
import { Send, CheckCircle, Mail, Bell, Star, Heart } from 'lucide-react';

const floatingIcons = [Mail, Bell, Star, Heart];

export default function NewsletterSubscription() {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState<'idle' | 'submitting' | 'success' | 'error'>('idle');
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('submitting');

    const formActionURL =
      'https://docs.google.com/forms/d/e/1FAIpQLSdgcIUerAMXC5Td1PzsJRYfPaDBVKl1wYQ4uAloyIz91kpL2w/formResponse';

    const formData = new FormData();
    formData.append('entry.1238202693', email); // ✅ correct ID;

    try {
      await fetch(formActionURL, {
        method: 'POST',
        mode: 'no-cors',
        body: formData,
      });

      setStatus('success');
      setEmail('');
      setTimeout(() => setStatus('idle'), 5000);
    } catch (error) {
      console.error('Submission error:', error);
      setStatus('error');
    }
  };

  return (
    <section className="relative py-32 overflow-hidden">
      {/* Background */}
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_50%_50%,_#1e1b4b_0%,_#312e81_25%,_#4338ca_50%,_#4f46e5_75%,_#6366f1_100%)]">
        <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48cGF0dGVybiBpZD0iZ3JpZCIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBwYXR0ZXJuVW5pdHM9InVzZXJTcGFjZU9uVXNlIj48cGF0aCBkPSJNIDIwIDAgTCAwIDAgMCAyMCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLW9wYWNpdHk9IjAuMSIgc3Ryb2tlLXdpZHRoPSIxIi8+PC9wYXR0ZXJuPjwvZGVmcz48cmVjdCB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyaWQpIi8+PC9zdmc+')] opacity-20" />
      </div>

      {/* Floating Icons */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        {floatingIcons.map((Icon, index) => (
          <div
            key={index}
            className="absolute animate-float"
            style={{
              left: `${25 * (index + 1)}%`,
              animationDelay: `${index * 0.5}s`,
              opacity: 0.1,
            }}
          >
            <Icon size={32} className="text-white" />
          </div>
        ))}
      </div>

      {/* Glowing Orbs */}
      <div className="absolute inset-0 pointer-events-none">
        <div className="absolute top-1/4 -left-10 w-40 h-40 bg-purple-500/30 rounded-full blur-3xl animate-pulse" />
        <div className="absolute bottom-1/4 -right-10 w-40 h-40 bg-blue-500/30 rounded-full blur-3xl animate-pulse delay-1000" />
      </div>

      {/* Form */}
      <div className="container relative mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-12">
          <h2 className="text-4xl font-bold text-white mb-6 animate-fade-in">
            Stay Connected & Inspired
          </h2>
          <p className="text-xl text-gray-300 mb-8 animate-fade-in leading-relaxed">
            Join our community of changemakers and get exclusive updates on our initiatives,
            success stories, and opportunities to make a difference.
          </p>
        </div>

        <div className="relative">
          <div className={`absolute inset-0 bg-white/5 rounded-2xl transition-all duration-500 ${isInputFocused ? 'blur-xl' : 'blur-none'}`} />
          <div className="relative bg-white/10 backdrop-blur-xl rounded-2xl p-8 shadow-2xl">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="relative">
                <input
                  type="email"
                  required
                  placeholder="Enter your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={() => setIsInputFocused(true)}
                  onBlur={() => setIsInputFocused(false)}
                  className="w-full px-6 py-4 bg-white/10 rounded-xl border border-white/20 text-white placeholder-gray-300 focus:outline-none focus:ring-2 focus:ring-white/20 transition-all duration-300"
                />
                <Mail className="absolute right-4 top-1/2 -translate-y-1/2 text-white/40" size={20} />
              </div>

              <button
                type="submit"
                disabled={status === 'submitting'}
                className="group w-full bg-gradient-to-r from-indigo-500 to-purple-500 hover:from-indigo-600 hover:to-purple-600 text-white px-8 py-4 rounded-xl font-semibold transition-all duration-300 transform hover:scale-[1.02] disabled:opacity-50 disabled:hover:scale-100 flex items-center justify-center gap-2 shadow-xl hover:shadow-2xl"
              >
                {status === 'submitting' ? (
                  <>
                    <div className="w-5 h-5 border-2 border-white/30 border-t-white rounded-full animate-spin" />
                    Subscribing...
                  </>
                ) : (
                  <>
                    Subscribe Now
                    <Send size={20} className="transform group-hover:translate-x-1 transition-transform" />
                  </>
                )}
              </button>
            </form>

            {status === 'success' && (
              <div className="mt-6 bg-green-500/20 backdrop-blur-sm rounded-xl p-4 flex items-center gap-3 animate-fade-in">
                <CheckCircle className="text-green-400" size={24} />
                <p className="text-green-400 font-medium">
                  Welcome aboard! Your email was submitted successfully.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
