import { useState } from 'react';
import { Menu, X } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="fixed top-0 left-0 right-0 z-50">
      <header className="bg-gray-900/95 backdrop-blur-sm border-b border-gray-800">
        <div className="container mx-auto px-4">
          <div className="flex items-center justify-between h-20">
            {/* Logo */}
            <Link to="/" className="flex items-center gap-2">
            <div className="flex items-center gap-3">
              <img
                src="https://efvuswbeeeiipabzoqsw.supabase.co/storage/v1/object/public/pub-imgs/home-pw-media/x5w15R01.svg"
                alt="Prince Wako Logo"
                className="w-10 h-10 transition-transform duration-300 hover:scale-110"
              />
              <div>
                <span className="font-bold text-white text-xl">Prince Wako</span>
                <span className="text-white text-sm block -mt-1">Foundation</span>
              </div>
            </div>


            </Link>

            {/* Desktop Navigation */}
            <nav className="hidden md:flex items-center gap-8">
              <Link to="/" className="text-white hover:text-gray-300 transition-colors">Home</Link>
              <Link to="/about" className="text-white hover:text-gray-300 transition-colors">About</Link>
              <Link to="/initiatives" className="text-white hover:text-gray-300 transition-colors">Initiatives</Link>
              <Link to="/impact" className="text-white hover:text-gray-300 transition-colors">Impact</Link>
              {/* <Link to="/contact" className="text-white hover:text-gray-300 transition-colors">Contact</Link> */}
              <Link to="/contact" className="bg-white text-gray-900 px-6 py-2 rounded-full font-semibold hover:bg-gray-100 transition-colors">
                Contact Now
              </Link>
            </nav>

            {/* Mobile Menu Button */}
            <button 
              className="md:hidden text-white"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>

          {/* Mobile Navigation */}
          {isMenuOpen && (
            <nav className="md:hidden py-4 border-t border-gray-800">
              <div className="flex flex-col gap-4">
                <Link to="/" className="text-white hover:text-gray-300 transition-colors">Home</Link>
                <Link to="/about" className="text-white hover:text-gray-300 transition-colors">About</Link>
                <Link to="/initiatives" className="text-white hover:text-gray-300 transition-colors">Initiatives</Link>
                <Link to="/impact" className="text-white hover:text-gray-300 transition-colors">Impact</Link>
                {/* <Link to="/contact" className="text-white hover:text-gray-300 transition-colors">Contact</Link> */}
                <Link to="/contact" className="bg-white text-gray-900 px-6 py-2 rounded-full font-semibold hover:bg-gray-100 transition-colors w-full text-center">
                  Contact Now
                </Link>
              </div>
            </nav>
          )}
        </div>
      </header>
    </div>
  );
}