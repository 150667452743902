import React from 'react';
import { BookOpen, Heart, Users, Globe } from 'lucide-react';

const impactStats = [
  {
    icon: BookOpen,
    value: '500+',
    label: 'Students Supported',
    description: 'Through our scholarship and school programs',
    gradient: 'from-blue-500 to-indigo-600'
  },
  {
    icon: Heart,
    value: '10+',
    label: 'Healthcare Projects',
    description: 'Delivered through mobile camps and clinics',
    gradient: 'from-rose-500 to-pink-600'
  },
  {
    icon: Users,
    value: '200+',
    label: 'Volunteers Engaged',
    description: 'Helping run community initiatives',
    gradient: 'from-amber-500 to-orange-600'
  },
  {
    icon: Globe,
    value: '10,000+',
    label: 'Lives Impacted',
    description: 'Across education, health, and empowerment',
    gradient: 'from-emerald-500 to-teal-600'
  }
];


const fundAllocation = [
  { category: 'Programs & Services', percentage: 80, color: 'bg-blue-500' },
  { category: 'Administrative', percentage: 15, color: 'bg-green-500' },
  { category: 'Outreach', percentage: 5, color: 'bg-yellow-500' }
];

export default function DonateImpact() {
  return (
    <section className="py-20 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-rose-600 to-purple-600 bg-clip-text text-transparent">
            Your Impact
          </h2>
          <p className="text-lg text-gray-600">
            See how your donations create real change in communities.
          </p>
        </div>

        <div className="grid md:grid-cols-4 gap-8 mb-16">
          {impactStats.map((stat, index) => (
            <div 
              key={index} 
              className="group bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
            >
              <div className={`w-16 h-16 bg-gradient-to-r ${stat.gradient} rounded-full flex items-center justify-center mx-auto mb-4 transform group-hover:scale-110 transition-transform duration-300`}>
                <stat.icon className="text-white" size={32} />
              </div>
              <div className="text-3xl font-bold mb-2 text-center bg-gradient-to-r from-rose-600 to-purple-600 bg-clip-text text-transparent">
                {stat.value}
              </div>
              <div className="text-lg font-semibold mb-2 text-center group-hover:text-rose-600 transition-colors duration-300">
                {stat.label}
              </div>
              <p className="text-gray-600 text-center text-sm group-hover:text-gray-700 transition-colors duration-300">
                {stat.description}
              </p>
            </div>
          ))}
        </div>

        <div className="max-w-2xl mx-auto bg-white rounded-xl shadow-lg p-8">
          <h3 className="text-2xl font-bold text-center mb-8 bg-gradient-to-r from-rose-600 to-purple-600 bg-clip-text text-transparent">
            How We Use Your Donations
          </h3>
          <div className="space-y-6">
            {fundAllocation.map((item, index) => (
              <div key={index} className="group">
                <div className="flex justify-between text-sm mb-2">
                  <span className="font-medium">{item.category}</span>
                  <span className="font-bold">{item.percentage}%</span>
                </div>
                <div className="relative h-4 bg-gray-100 rounded-full overflow-hidden">
                  <div
                    className={`h-full ${item.color} transform origin-left transition-transform duration-1000 ease-out group-hover:scale-x-105`}
                    style={{ width: `${item.percentage}%` }}
                  >
                    <div className="absolute inset-0 bg-white/20 animate-shimmer" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}