import { Quote, Star } from 'lucide-react';
import { Link } from 'react-router-dom';

const testimonials = [
  {
    quote: "The foundation built our school and gave my daughter hope. She now dreams of becoming a doctor.",
    author: "Sarah, Mafubira County, Jinja",
    role: "Mother",
    rating: 5
  },
  {
    quote: "The medical camp saved my life. I had no access to treatment before.",
    author: "Maria, Kaliro Community.",
    role: "Beneficiary",
     rating: 5
  }
];

export default function Impact() {
  return (
    <section className="py-20 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16 animate-fade-in">
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
            Our Impact
          </h2>
          <p className="text-lg text-gray-600">
            Real stories from the communities we serve.
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          {testimonials.map((testimonial, index) => (
            <div 
              key={index} 
              className="group bg-white rounded-xl p-8 shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1"
            >
              <div className="flex items-start gap-4">
                <div className="relative">
                  <Quote className="absolute -bottom-2 -right-2 text-indigo-600 bg-white rounded-full p-1" size={24} />
                </div>
                <div className="flex-1">
                  <div className="flex items-center gap-1 mb-2">
                    {[...Array(testimonial.rating)].map((_, i) => (
                      <Star key={i} className="text-amber-400 fill-current" size={16} />
                    ))}
                  </div>
                  <p className="text-lg text-gray-700 mb-4 italic group-hover:text-gray-900 transition-colors duration-300">
                    "{testimonial.quote}"
                  </p>
                  <div>
                    <p className="font-semibold text-gray-900">{testimonial.author}</p>
                    <p className="text-gray-600">{testimonial.role}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-12 text-center">
        <Link
          to="/impact"
          className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white px-8 py-3 rounded-full font-semibold hover:from-indigo-700 hover:to-purple-700 transform hover:scale-105 transition-all duration-300 shadow-lg hover:shadow-xl"
        >
          View More Success Stories
        </Link>

        </div>
      </div>
    </section>
  );
}