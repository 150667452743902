import React from 'react';
import { Heart, ArrowRight } from 'lucide-react';

export default function DonateHero() {
  return (
    <div className="relative h-[80vh] flex items-center overflow-hidden">
      <div className="absolute inset-0 z-0">
        <img
          src="https://efvuswbeeeiipabzoqsw.supabase.co/storage/v1/object/public/pub-imgs/home-pw-media/prince-wako-volunteers-with-organization-tshirts.jpg"
          alt="Community impact"
          className="w-full h-full object-cover transform scale-105 animate-ken-burns"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-rose-900/90 to-purple-900/70" />
      </div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-3xl animate-fade-in">
          <h1 className="text-5xl md:text-6xl font-bold mb-6 text-white">
            Your Support{' '}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-rose-300 to-purple-300">
              Changes Lives
            </span>
          </h1>
          <p className="text-xl md:text-2xl mb-8 text-gray-200">
            Every donation helps us create lasting positive change in communities across Uganda.
          </p>
          <button 
            onClick={() => document.getElementById('donation-options')?.scrollIntoView({ behavior: 'smooth' })}
            className="group bg-gradient-to-r from-rose-500 to-purple-500 hover:from-rose-600 hover:to-purple-600 text-white px-8 py-3 rounded-full font-semibold flex items-center gap-2 transition-all duration-300 transform hover:scale-105 hover:shadow-xl"
          >
            Donate Now
            <Heart size={20} className="transform group-hover:scale-110 transition-transform" />
          </button>
        </div>
      </div>

      {/* Animated background shapes */}
      <div className="absolute inset-0 z-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-40 -right-40 w-80 h-80 bg-purple-500/10 rounded-full blur-3xl animate-blob" />
        <div className="absolute -bottom-40 -left-40 w-80 h-80 bg-rose-500/10 rounded-full blur-3xl animate-blob animation-delay-2000" />
      </div>
    </div>
  );
}