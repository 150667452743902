import { BookOpen, Download, Users, FileText } from 'lucide-react';
import Header from '../components/Header';
import NewsletterSubscription from '../components/NewsletterSubscription';
import ArticlesSection from '../components/resources/ArticlesSection';
import Footer from '../components/Footer';

const resources = [
  {
    category: 'Scholarships & Financial Aid',
    items: [
      {
        title: 'Scholarship Application Guide',
        description: 'Step-by-step guide on how to apply for our scholarships',
        link: '#',
        type: 'PDF'
      },
      {
        title: 'Financial Aid FAQ',
        description: 'Common questions about our financial support programs',
        link: '#',
        type: 'Article'
      }
    ]
  },
  {
    category: 'Study Tips & Academic Support',
    items: [
      {
        title: 'Effective Study Strategies',
        description: 'Research-backed techniques for academic success',
        link: '#',
        type: 'Guide'
      },
      {
        title: 'Time Management Tools',
        description: 'Templates and tips for better time management',
        link: '#',
        type: 'Resource'
      }
    ]
  },
  {
    category: 'Mentorship & Community',
    items: [
      {
        title: 'Mentorship Program Guide',
        description: 'Learn about our mentorship opportunities',
        link: '#',
        type: 'Guide'
      },
      {
        title: 'Volunteer Opportunities',
        description: 'Ways to give back to your community',
        link: '#',
        type: 'Article'
      }
    ]
  }
];

const studentArticles = [
  {
    title: 'How to Secure a Full Scholarship: A Complete Guide',
    excerpt: 'Learn the proven strategies and tips that have helped our students secure full scholarships for their education.',
    image: 'https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80',
    author: 'Sarah Johnson',
    readTime: '8 min read',
    category: 'Scholarships',
    cta: {
      text: 'Read Guide & Apply Now',
      action: '/apply-scholarship'
    }
  },
  {
    title: 'Success Stories: From Student to Community Leader',
    excerpt: 'Discover how our mentorship program transformed the lives of students and helped them become community leaders.',
    image: 'https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80',
    author: 'Michael Chen',
    readTime: '6 min read',
    category: 'Success Stories',
    cta: {
      text: 'Join Mentorship Program',
      action: '/mentorship'
    }
  },
  {
    title: 'Top Study Techniques Used by High Achievers',
    excerpt: 'Expert-backed study methods and time management strategies that consistently produce excellent results.',
    image: 'https://images.unsplash.com/photo-1434030216411-0b793f4b4173?ixlib=rb-4.0.3&auto=format&fit=crop&w=1000&q=80',
    author: 'Dr. Emily White',
    readTime: '10 min read',
    category: 'Academic Tips',
    cta: {
      text: 'Get Study Resources',
      action: '/study-resources'
    }
  }
];

export default function StudentResources() {
  return (
    <div className="min-h-screen">
      <Header />
      
      <main className="pt-20">
        {/* Hero Section */}
        <section className="bg-gray-900 text-white py-20">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h1 className="text-4xl md:text-5xl font-bold mb-6">Student Resources</h1>
              <p className="text-xl text-gray-300">
                Empowering students with tools, guidance, and opportunities for academic success and personal growth.
              </p>
            </div>
          </div>
        </section>

        {/* Resources Grid */}
        <section className="py-20">
          <div className="container mx-auto px-4">
            <div className="max-w-5xl mx-auto">
              {resources.map((section, index) => (
                <div key={index} className="mb-16">
                  <h2 className="text-3xl font-bold mb-8">{section.category}</h2>
                  <div className="grid md:grid-cols-2 gap-8">
                    {section.items.map((item, idx) => (
                      <div key={idx} className="bg-white rounded-xl shadow-lg p-6">
                        <div className="flex items-start gap-4">
                          <div className="w-12 h-12 bg-gray-900 rounded-lg flex items-center justify-center shrink-0">
                            {item.type === 'PDF' ? (
                              <Download className="text-white" size={24} />
                            ) : item.type === 'Guide' ? (
                              <BookOpen className="text-white" size={24} />
                            ) : item.type === 'Article' ? (
                              <FileText className="text-white" size={24} />
                            ) : (
                              <Users className="text-white" size={24} />
                            )}
                          </div>
                          <div>
                            <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
                            <p className="text-gray-600 mb-4">{item.description}</p>
                            <a
                              href={item.link}
                              className="text-gray-900 font-semibold hover:text-gray-700 transition-colors inline-flex items-center gap-2"
                            >
                              Access Resource
                              <Download size={20} />
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Email Updates Section */}
        <section className="bg-gray-50 py-20">
          <div className="container mx-auto px-4">
            <div className="max-w-3xl mx-auto text-center">
              <h2 className="text-3xl font-bold mb-6">Stay Updated</h2>
              <p className="text-lg text-gray-600 mb-8">
                Subscribe to receive monthly updates about new resources, opportunities, and student success stories.
              </p>
              <form className="max-w-md mx-auto">
                <div className="flex gap-4">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="flex-1 px-4 py-3 rounded-lg border focus:ring-2 focus:ring-gray-900 focus:border-transparent"
                  />
                  <button
                    type="submit"
                    className="bg-gray-900 text-white px-6 py-3 rounded-lg font-semibold hover:bg-gray-800 transition-colors"
                  >
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>

        {/* Add Articles Section before Newsletter */}
        <ArticlesSection
          title="Featured Articles & Guides"
          description="Discover in-depth resources and success stories to help you achieve your academic goals."
          articles={studentArticles}
        />

        <NewsletterSubscription />
      </main>

      <Footer />
    </div>
  );
}