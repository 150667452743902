import { Users, BookOpen, Droplet } from 'lucide-react';

const initiatives = [
  {
    title: 'Kampala Education Center',
    description: 'Providing quality education and support to underprivileged children.',
    image: 'https://efvuswbeeeiipabzoqsw.supabase.co/storage/v1/object/public/pub-imgs/home-pw-media/girl-child-programmes-prince-wako-foundation.jpg',
    icon: BookOpen,
    metrics: [
      { label: 'Students Enrolled', value: '200+' },
      { label: 'Teachers Employed', value: '10' },
      { label: 'Classrooms Built', value: '5' }
    ]
  },
  {
    title: 'Clean Water Initiative',
    description: 'Providing sustainable access to clean water in rural communities across Uganda.',
    image: 'https://efvuswbeeeiipabzoqsw.supabase.co/storage/v1/object/public/pub-imgs/home-pw-media/girls-fetching-water%20(1).jpg',
    icon: Droplet,
    metrics: [
      { label: 'Wells Constructed', value: '5+' },
      { label: 'Communities Served', value: '10' },
      { label: 'People Impacted', value: '3,000+' }
    ]
  },
  {
    title: 'Community Leadership Program',
    description: 'Empowering local leaders with skills and resources to drive sustainable development.',
    image: 'https://efvuswbeeeiipabzoqsw.supabase.co/storage/v1/object/public/pub-imgs/home-pw-media/girl-taking-a-firm-stand-against-chil-marriages-prince-wako-foundation.jpg',
    icon: Users,
    metrics: [
      { label: 'Leaders Trained', value: '50+' },
      { label: 'Projects Initiated', value: '5' }
    ]
  }
];

export default function FeaturedInitiatives() {
  return (
    <section className="py-20 bg-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold mb-6">Featured Initiatives</h2>
          <p className="text-lg text-gray-600">
            Explore our key projects making significant impact in communities.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {initiatives.map((initiative, index) => (
            <div key={index} className="bg-white rounded-xl shadow-lg overflow-hidden">
              <div className="h-48 relative">
                <img 
                  src={initiative.image}
                  alt={initiative.title}
                  className="w-full h-full object-cover"
                />
                <div className="absolute bottom-4 left-4">
                  <div className="w-10 h-10 bg-gray-900 rounded-lg flex items-center justify-center">
                    <initiative.icon className="text-white" size={20} />
                  </div>
                </div>
              </div>
              
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-3">{initiative.title}</h3>
                <p className="text-gray-600 mb-6">{initiative.description}</p>
                
                <div className="space-y-3 mb-6">
                  {initiative.metrics.map((metric, idx) => (
                    <div key={idx} className="flex justify-between">
                      <span className="text-gray-600">{metric.label}</span>
                      <span className="font-semibold">{metric.value}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}