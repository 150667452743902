import React from 'react';
import Header from '../components/Header';
import AboutHero from '../components/about/AboutHero';
import AboutStory from '../components/about/AboutStory';
import MissionGoals from '../components/about/MissionGoals';
import Leadership from '../components/about/Leadership';
import ImpactOverview from '../components/about/ImpactOverview';
import AboutCTA from '../components/about/AboutCTA';
import NewsletterSubscription from '../components/NewsletterSubscription';
import Footer from '../components/Footer';

export default function About() {
  return (
    <div className="min-h-screen relative">
      <Header />
      <main className="relative z-0">
        <AboutHero />
        <AboutStory />
        <MissionGoals />
        <Leadership />
        <ImpactOverview />
        <AboutCTA />
        <NewsletterSubscription />
      </main>
      <Footer />
    </div>
  );
}