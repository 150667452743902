import { BookOpen, Heart, Leaf, Users, CheckCircle } from 'lucide-react';

const categories = [
  {
    icon: BookOpen,
    title: 'Education & Literacy',
    description: 'Empowering through knowledge with scholarships, school construction, and educational resources.',
    initiatives: [
      'School Building Program',
      'Scholarship Fund',
      'Teacher Training',
      'Digital Learning Labs'
    ],
    gradient: 'from-blue-500 to-indigo-600',
    stats: { completed: 200, ongoing: 12 }
  },
  {
    icon: Heart,
    title: 'Healthcare & Wellness',
    description: 'Providing essential healthcare services and promoting community wellness.',
    initiatives: [
      'Mobile Medical Camps',
      'Maternal Care',
      'Community Clinics',
      'Health Education'
    ],
    gradient: 'from-rose-500 to-pink-600',
    stats: { completed: 10, ongoing: 0 }
  },
  {
    icon: Leaf,
    title: 'Environmental Sustainability',
    description: 'Creating a greener future through conservation and sustainable practices.',
    initiatives: [
      'Tree Planting',
      'Clean Water Projects',
      'Waste Management',
      'Renewable Energy'
    ],
    gradient: 'from-emerald-500 to-teal-600',
    stats: { completed: 20, ongoing: 5 }
  },
  {
    icon: Users,
    title: 'Community Development',
    description: 'Building stronger communities through infrastructure and skill development.',
    initiatives: [
      'Housing Projects',
      'Vocational Training',
      'Microfinance Programs',
      'Leadership Development'
    ],
    gradient: 'from-amber-500 to-orange-600',
    stats: { completed: 50, ongoing: 5 }
  }
];

export default function InitiativesOverview() {
  return (
    <section className="py-20 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
            Our Focus Areas
          </h2>
          <p className="text-lg text-gray-600">
            Through our key initiatives, we create sustainable impact across multiple sectors.
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          {categories.map((category, index) => (
            <div 
              key={index} 
              className="group bg-white rounded-xl p-8 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
            >
              <div className="flex items-start gap-4 mb-6">
                <div className={`w-12 h-12 bg-gradient-to-r ${category.gradient} rounded-lg flex items-center justify-center shrink-0 transform group-hover:scale-110 transition-transform duration-300`}>
                  <category.icon className="text-white" size={24} />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2 group-hover:text-indigo-600 transition-colors duration-300">
                    {category.title}
                  </h3>
                  <p className="text-gray-600">{category.description}</p>
                </div>
              </div>

              <div className="space-y-3 mb-6">
                {category.initiatives.map((initiative, idx) => (
                  <div 
                    key={idx} 
                    className="flex items-center gap-2 text-gray-700 group-hover:text-gray-900 transition-colors duration-300"
                  >
                    <CheckCircle className="text-green-500" size={16} />
                    <span>{initiative}</span>
                  </div>
                ))}
              </div>

              <div className="flex items-center justify-between pt-4 border-t border-gray-100">
                <div className="flex gap-4">
                  <div className="text-sm">
                    <span className="text-gray-500">Completed:</span>
                    <span className="ml-1 font-semibold text-gray-900">{category.stats.completed}</span>
                  </div>
                  <div className="text-sm">
                    <span className="text-gray-500">Ongoing:</span>
                    <span className="ml-1 font-semibold text-gray-900">{category.stats.ongoing}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}