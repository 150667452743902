import React from 'react';
import { Heart, Users, DollarSign } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function ImpactCTA() {
  return (
    <section className="py-20 bg-gray-900 text-white">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold mb-6">Every Contribution Creates Lasting Change</h2>
          <p className="text-xl opacity-90">
            Join us in our mission to transform communities and create sustainable impact.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          <div className="text-center">
            <div className="w-16 h-16 bg-white/10 rounded-full flex items-center justify-center mx-auto mb-4">
              <DollarSign className="text-white" size={32} />
            </div>
            <h3 className="text-xl font-semibold mb-3">Donate Now</h3>
            <p className="opacity-90 mb-4">
              Help fund more community projects and create lasting impact.
            </p>
            <Link to="/contact#contact-form" className="bg-white text-gray-900 px-6 py-2 rounded-full font-semibold hover:bg-gray-100 transition-colors">
              Make a Donation
            </Link>
          </div>

          <div className="text-center">
            <div className="w-16 h-16 bg-white/10 rounded-full flex items-center justify-center mx-auto mb-4">
              <Users className="text-white" size={32} />
            </div>
            <h3 className="text-xl font-semibold mb-3">Volunteer</h3>
            <p className="opacity-90 mb-4">
              Become part of our mission and help create positive change.
            </p>
            <Link to="/contact#contact-form" className="bg-white text-gray-900 px-6 py-2 rounded-full font-semibold hover:bg-gray-100 transition-colors">
              Join as Volunteer
            </Link>
          </div>

          <div className="text-center">
            <div className="w-16 h-16 bg-white/10 rounded-full flex items-center justify-center mx-auto mb-4">
              <Heart className="text-white" size={32} />
            </div>
            <h3 className="text-xl font-semibold mb-3">Fundraise</h3>
            <p className="opacity-90 mb-4">
              Create your own fundraising campaign to support our cause.
            </p>
            <Link to="/contact#contact-form" className="bg-white text-gray-900 px-6 py-2 rounded-full font-semibold hover:bg-gray-100 transition-colors">
              Start Campaign
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}