import { Link } from 'react-router-dom';
import { Target, Heart, Globe, ArrowRight, Users, BookOpen, HandHeart } from 'lucide-react';

const stats = [
  {
    icon: Users,
    value: '10,000+',
    label: 'Lives Impacted',
    gradient: 'from-blue-500 to-indigo-600'
  },
  {
    icon: BookOpen,
    value: '15+',
    label: 'Projects Completed',
    gradient: 'from-rose-500 to-pink-600'
  },
  {
    icon: HandHeart,
    value: '20+',
    label: 'Communities Served',
    gradient: 'from-amber-500 to-orange-600'
  }
];

export default function About() {
  return (
    <section className="py-24 bg-white relative overflow-hidden">
      {/* Background decoration */}
      <div className="absolute inset-0 bg-gradient-to-b from-gray-50 to-transparent" />
      <div className="absolute inset-0 bg-[radial-gradient(circle_800px_at_100%_200px,rgba(219,39,119,0.05),transparent)]" />
      
      <div className="container mx-auto px-4 relative">
        <div className="grid lg:grid-cols-2 gap-12 lg:gap-20 items-center">
          {/* Left Column - Content */}
          <div className="space-y-12">
            <div className="space-y-6 max-w-2xl">
              <h2 className="text-4xl lg:text-5xl font-bold bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent">
                Transforming Lives Through Sustainable Impact
              </h2>
              <p className="text-lg text-gray-600 leading-relaxed">
                Founded on the principles of compassion and sustainable development, we work tirelessly to create lasting positive change in underserved communities across Uganda.
              </p>
            </div>

            <div className="grid sm:grid-cols-3 gap-8">
              {stats.map((stat, index) => (
                <div 
                  key={index}
                  className="group bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
                >
                  <div className={`w-12 h-12 mb-4 bg-gradient-to-r ${stat.gradient} rounded-lg flex items-center justify-center transform group-hover:scale-110 transition-transform duration-300`}>
                    <stat.icon className="text-white" size={24} />
                  </div>
                  <div className="text-2xl font-bold mb-1">{stat.value}</div>
                  <div className="text-gray-600">{stat.label}</div>
                </div>
              ))}
            </div>

            <div className="space-y-6">
              <div className="flex flex-col sm:flex-row gap-4">
                <div className="flex items-center gap-2 text-gray-700">
                  <Target className="text-indigo-600" size={20} />
                  <span>Clear Mission</span>
                </div>
                <div className="flex items-center gap-2 text-gray-700">
                  <Heart className="text-rose-600" size={20} />
                  <span>Passionate Team</span>
                </div>
                <div className="flex items-center gap-2 text-gray-700">
                  <Globe className="text-emerald-600" size={20} />
                  <span>Global Impact</span>
                </div>
              </div>

              <Link
                to="/initiatives"
                className="group inline-flex items-center gap-2 text-indigo-600 font-semibold hover:text-indigo-700 transition-colors"
              >
                Learn More About Our Work
                <ArrowRight size={20} className="transform group-hover:translate-x-1 transition-transform" />
              </Link>
              
            </div>
          </div>

          {/* Right Column - Image Grid */}
          <div className="relative">
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-4">
                <div className="aspect-[4/3] rounded-2xl overflow-hidden">
                  <img
                    src="https://efvuswbeeeiipabzoqsw.supabase.co/storage/v1/object/public/pub-imgs/home-pw-media/prince-wako-a-volunteer-with-kids.jpg"
                    alt="Community support"
                    className="w-full h-full object-cover hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <div className="aspect-[4/5] rounded-2xl overflow-hidden">
                  <img
                    src="https://efvuswbeeeiipabzoqsw.supabase.co/storage/v1/object/public/pub-imgs/home-pw-media/prince-wako-hand.jpg"
                    alt="Education initiative"
                    className="w-full h-full object-cover hover:scale-105 transition-transform duration-300"
                  />
                </div>
              </div>
              <div className="space-y-4 pt-8">
                <div className="aspect-[4/5] rounded-2xl overflow-hidden">
                  <img
                    src="https://efvuswbeeeiipabzoqsw.supabase.co/storage/v1/object/public/pub-imgs/home-pw-media/prince-wako-volunteers-with-organization-tshirts.jpg"
                    alt="Healthcare program"
                    className="w-full h-full object-cover hover:scale-105 transition-transform duration-300"
                  />
                </div>
                <div className="aspect-[4/3] rounded-2xl overflow-hidden">
                  <img
                    src="https://efvuswbeeeiipabzoqsw.supabase.co/storage/v1/object/public/pub-imgs/home-pw-media/prince-wako-volunteering-event.jpg"
                    alt="Community gathering"
                    className="w-full h-full object-cover hover:scale-105 transition-transform duration-300"
                  />
                </div>
              </div>
            </div>

            {/* Decorative elements */}
            <div className="absolute -top-4 -right-4 w-72 h-72 bg-gradient-to-br from-indigo-500/10 to-purple-500/10 rounded-full blur-3xl" />
            <div className="absolute -bottom-4 -left-4 w-72 h-72 bg-gradient-to-br from-rose-500/10 to-orange-500/10 rounded-full blur-3xl" />
          </div>
        </div>
      </div>
    </section>
  );
}