import { ArrowRight, Heart, Users, Globe } from 'lucide-react';
import { Link } from 'react-router-dom';

const impactHighlights = [
  {
    icon: Heart,
    title: '10,000+',
    description: 'Lives Impacted',
    gradient: 'from-rose-500 to-pink-600'
  },
  {
    icon: Users,
    title: '20+',
    description: 'Communities Served',
    gradient: 'from-blue-500 to-indigo-600'
  },
  {
    icon: Globe,
    title: 'Africa',
    description: 'Regions Covered',
    gradient: 'from-emerald-500 to-teal-600'
  }
];

export default function ImpactHero() {
  return (
    <div className="relative min-h-[90vh] flex items-center overflow-hidden pt-20 pb-16">
      <div className="absolute inset-0 z-0">
        <img
          src="https://efvuswbeeeiipabzoqsw.supabase.co/storage/v1/object/public/pub-imgs/home-pw-media/prince-wako-foundation-a-volunteer-with-beneficiary-kids.jpg"
          alt="Community impact"
          className="w-full h-full object-cover transform scale-105 animate-ken-burns"
        />
        <div className="absolute inset-0 bg-gradient-to-r from-gray-900/90 to-indigo-900/70" />
      </div>
      
      <div className="container mx-auto px-4 relative z-10">
        <div className="max-w-3xl animate-fade-in space-y-12">
          <div className="space-y-6">
            <h1 className="text-5xl md:text-6xl font-bold text-white leading-tight">
              Transforming Lives,{' '}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-300 to-purple-300 block md:inline">
                One Initiative at a Time
              </span>
            </h1>
            <p className="text-xl md:text-2xl text-gray-200 leading-relaxed">
              See the difference your support makes in communities across Uganda. Explore real-life success stories, ongoing projects, and impact data.
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-6">
            {impactHighlights.map((highlight, index) => (
              <div 
                key={index}
                className="group bg-white/10 backdrop-blur-sm rounded-xl p-6 hover:bg-white/20 transition-all duration-300 transform hover:-translate-y-1"
              >
                <div className={`w-12 h-12 mb-4 bg-gradient-to-r ${highlight.gradient} rounded-lg flex items-center justify-center transform group-hover:scale-110 transition-transform duration-300`}>
                  <highlight.icon className="text-white" size={24} />
                </div>
                <h3 className="text-2xl font-bold text-white mb-1">{highlight.title}</h3>
                <p className="text-gray-300">{highlight.description}</p>
              </div>
            ))}
          </div>

          <div className="flex flex-wrap gap-4 pb-8">
            <Link to="/contact#contact-form" className="group bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600 text-white px-8 py-3 rounded-full font-semibold flex items-center gap-2 transition-all duration-300 transform hover:scale-105 hover:shadow-xl">
              Support Our Work
              <ArrowRight size={20} className="transform group-hover:translate-x-1 transition-transform" />
            </Link>
          </div>
        </div>
      </div>

      {/* Animated background shapes */}
      <div className="absolute inset-0 z-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-40 -right-40 w-80 h-80 bg-purple-500/10 rounded-full blur-3xl animate-blob" />
        <div className="absolute -bottom-40 -left-40 w-80 h-80 bg-blue-500/10 rounded-full blur-3xl animate-blob animation-delay-2000" />
      </div>
    </div>
  );
}