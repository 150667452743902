// import React from 'react';
import Header from '../components/Header';
import ImpactHero from '../components/impact/ImpactHero';
import ImpactMetrics from '../components/impact/ImpactMetrics';
// import SuccessStories from '../components/impact/SuccessStories';
import OngoingProjects from '../components/impact/OngoingProjects';
// import Testimonials from '../components/impact/Testimonials';
// import MediaGallery from '../components/impact/MediaGallery';
import ImpactCTA from '../components/impact/ImpactCTA';
import NewsletterSubscription from '../components/NewsletterSubscription';
import Footer from '../components/Footer';

export default function CommunityImpact() {
  return (
    <div className="min-h-screen relative">
      <Header />
      <main className="relative z-0">
        <ImpactHero />
        <ImpactMetrics />
        {/* <SuccessStories /> */}
        <OngoingProjects />
        {/* <Testimonials /> */}
        {/* <MediaGallery /> */}
        <ImpactCTA />
        <NewsletterSubscription />
      </main>
      <Footer />
    </div>
  );
}