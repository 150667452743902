import { BookOpen, Heart, Home, Droplet } from 'lucide-react';

const goals = [
  {
    icon: BookOpen,
    title: 'Improve literacy and vocational skills',
    description: 'Providing quality education through scholarships, schools, and vocational programs enhancing practical skills.',
    gradient: 'from-blue-500 to-indigo-500'
  },
  {
    icon: Heart,
    title: 'Healthcare Access',
    description: 'Establishing clinics, providing medical aid, and implementing wellness programs.',
    gradient: 'from-pink-500 to-rose-500'
  },
  {
    icon: Home,
    title: 'Community Development',
    description: 'Building sustainable infrastructure and empowering local leadership.',
    gradient: 'from-amber-500 to-orange-500'
  },
  {
    icon: Droplet,
    title: 'Environmental conservation',
    description: 'Driving Sustainable Intitiatives to Preserve Earth for Future Generations',
    gradient: 'from-emerald-500 to-teal-500'
  }
];

export default function MissionGoals() {
  return (
    <section className="py-20 bg-gradient-to-b from-gray-50 to-white">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
            Our Mission & Goals
          </h2>
          <p className="text-lg text-gray-600">
            We are dedicated to creating lasting positive change through focused initiatives in education, healthcare, and community development.
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-8">
          {goals.map((goal, index) => (
            <div 
              key={index} 
              className="group bg-white rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
            >
              <div className="flex items-start gap-4">
                <div className={`w-12 h-12 bg-gradient-to-r ${goal.gradient} rounded-lg flex items-center justify-center shrink-0 transform group-hover:scale-110 transition-transform duration-300`}>
                  <goal.icon className="text-white" size={24} />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2 group-hover:text-indigo-600 transition-colors duration-300">
                    {goal.title}
                  </h3>
                  <p className="text-gray-600 group-hover:text-gray-700 transition-colors duration-300">
                    {goal.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}