import { Phone, Mail, MessageCircle, Facebook, Twitter, Linkedin, Instagram, Youtube } from 'lucide-react';

const contactDetails = {
  phone: '+256 706 474 831',
  email: 'princewakofoundation@gmail.com',
  whatsapp: '+47 455 83 019',
  social: [
    {
      icon: Facebook,
      label: 'Facebook',
      link: 'https://www.facebook.com/PrinceWakoFoundation',
      gradient: 'from-blue-500 to-blue-600'
    },
    {
      icon: Twitter,
      label: 'Twitter (X)',
      link: 'https://x.com/princewakoorg',
      gradient: 'from-blue-400 to-blue-500'
    },
    {
      icon: Linkedin,
      label: 'LinkedIn',
      link: 'https://www.linkedin.com/company/prince-wako-foundation',
      gradient: 'from-blue-600 to-blue-700'
    },
    {
      icon: Instagram,
      label: 'Instagram',
      link: 'https://www.instagram.com/princewakofoundation',
      gradient: 'from-pink-500 to-purple-500'
    },
    {
      icon: Youtube,
      label: 'YouTube',
      link: 'https://www.youtube.com/@princewakofoundationorgani2429',
      gradient: 'from-red-500 to-red-600'
    }
  ]
};

export default function ContactInfo() {
  return (
    <section className="py-20 bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto">
          <div className="grid md:grid-cols-2 gap-12">
            
            {/* Contact Methods */}
            <div className="group">
              <h2 className="text-3xl font-bold mb-8 bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
                Get in Touch
              </h2>
              <div className="space-y-6">
                
                {/* Call Us */}
                <a 
                  href={`tel:${contactDetails.phone.replace(/\s+/g, '')}`}
                  className="flex items-start gap-4 bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
                >
                  <div className="w-12 h-12 bg-gradient-to-r from-rose-500 to-pink-500 rounded-lg flex items-center justify-center shrink-0">
                    <Phone className="text-white" size={24} />
                  </div>
                  <div>
                    <h3 className="font-semibold mb-1">Call Us</h3>
                    <p className="text-gray-600">Phone: {contactDetails.phone}</p>
                    <p className="text-gray-600">WhatsApp: {contactDetails.whatsapp}</p>
                  </div>
                </a>

                {/* Email Us */}
                <a 
                  href={`mailto:${contactDetails.email}`}
                  className="flex items-start gap-4 bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
                >
                  <div className="w-12 h-12 bg-gradient-to-r from-amber-500 to-orange-500 rounded-lg flex items-center justify-center shrink-0">
                    <Mail className="text-white" size={24} />
                  </div>
                  <div>
                    <h3 className="font-semibold mb-1">Email Us</h3>
                    <p className="text-gray-600">{contactDetails.email}</p>
                  </div>
                </a>

                {/* WhatsApp Chat */}
                <a 
                  href={`https://wa.me/${contactDetails.whatsapp.replace(/\D/g, '')}`}
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="flex items-start gap-4 bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
                >
                  <div className="w-12 h-12 bg-gradient-to-r from-green-500 to-emerald-500 rounded-lg flex items-center justify-center shrink-0">
                    <MessageCircle className="text-white" size={24} />
                  </div>
                  <div>
                    <h3 className="font-semibold mb-1">Live Chat</h3>
                    <p className="text-gray-600">Chat with us on WhatsApp</p>
                  </div>
                </a>

              </div>
            </div>

            {/* Social Media */}
            <div>
              <h2 className="text-3xl font-bold mb-8 bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
                Connect With Us
              </h2>
              <p className="text-gray-600 mb-8">
                Follow us on social media to stay updated with our latest initiatives and impact stories.
              </p>
              <div className="grid grid-cols-5 gap-4">
                {contactDetails.social.map((platform, index) => (
                  <a
                    key={index}
                    href={platform.link}
                    className="group relative w-12 h-12 bg-white rounded-full flex items-center justify-center shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1"
                    aria-label={platform.label}
                  >
                    <div className={`absolute inset-0 bg-gradient-to-r ${platform.gradient} rounded-full opacity-0 group-hover:opacity-100 transition-opacity duration-300`} />
                    <platform.icon className="relative z-10 text-gray-600 group-hover:text-white transition-colors duration-300" size={24} />
                  </a>
                ))}
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}
